<template>
	<el-row>
		<div class="number">
			<div>
				总共
				<span>{{currtotal}}</span> 条记录，每页显示
				<span>{{currpageSize}}</span> 条
			</div>
		</div>
		<div class="pag-wrap">
			<el-pagination background class="pag" @current-change="pageChange" :current-page='currpageIndex' layout="prev, pager, next"
			 :page-size="currpageSize" :total="currtotal"></el-pagination>
		</div>
	</el-row>
</template>
<script>
export default {
  data () {
    return {
      currpageIndex: 1,
      currpageSize: this.pagesize,
      currservicePage: this.options.servicePage,
      currtotal: 0,
      pagingResult: [],
      serviceData: [],
      yuansiData: [],
      lastType: 0,
      lsatSH: true
    }
  },
  props: {
    pagesize: {
      type: Number,
      default: 10
    },
    options: {
      type: Object
    }
  },
  created () {
    if (!this.currservicePage) {
      this.frontPaging()
    } else {
      this.serach()
    }
  },
  methods: {
    pageChange (val) {
      if (val) {
        this.currpageIndex = val
      }
      if (!this.currservicePage) {
        this.pagingResult = this.$utils.jsPagination(
          this.currpageIndex,
          this.currpageSize,
          this.serviceData
        )
        this.$emit('getPagingResult', this.pagingResult)
      } else {
        this.serach()
      }
    },
    serach (i) {
      if (i) {
        this.currpageIndex = 1
      }
      if (this.currservicePage) {
        this.$axios
          .get(
            `${this.options.url}&pageIndex=${this.currpageIndex}&PageSize=${this.currpageSize}&projectId=${localStorage.eleProjectId}`
          )
          .then(res => {
            console.log(res)
            this.pagingResult = res.data
            this.currtotal = res.total
            this.$emit('getPagingResult', this.pagingResult)
          })
      } else {
        // 前端查询分页
        this.fuzzySearch()
      }
    },
    frontPaging () {
      this.$axios.get(this.options.url).then(res => {
        console.log(res)
        res = res.reverse()
        if (this.options.url == '/api/space/getpersonspace') {
          res.forEach(item => {
            if (item.ownSpaceName == item.personSpaceName) {
              item.ownSpaceName = '个人'
            }
          })
        }
        this.yuansiData = res
        this.serviceData = res
        this.currtotal = this.serviceData.length
        this.pageChange(1)
      })
    },
    // 前端搜索
    fuzzySearch () {
      var reg = new RegExp(this.options.param.serachText)
      var result = []
      this.options.serachField.forEach(field => {
        var list = this.yuansiData.filter(v => {
          return reg.test(v[field])
        })
        result = result.concat(list)
      })
      this.serviceData = Array.from(new Set(result)) // 数组去重
      this.currtotal = this.serviceData.length
      this.pageChange(1)
    },
    // 前端导出excel时，改变当前每页显示条数
    export_pagesize (val, a, b) {
      // 如果总条数小于等于当前每页显示条数，则直接下载
      if (this.currtotal <= this.currpageSize) {
        this.$utils.exportExcel(a, b)
        return
      }
      console.log('超过')
      this.currpageSize = val
      this.pageChange(1)
      // 模拟异步导出
      setTimeout(() => {
        this.$utils.exportExcel(a, b)
        // 还原每页显示条数
        this.currpageSize = 10
        this.pageChange(1)
      }, 1)
    },
    sort (type) {
      // console.log(type)
      function compare (property) {
        return function (a, b) {
          var value1 = a[property]
          var value2 = b[property]
          return value1 - value2
        }
      }

      function compare2 (property) {
        return function (a, b) {
          var value1 = a[property]
          var value2 = b[property]
          return value2 - value1
        }
      }

      function hybirdSort (info, name, lsatSH) {
        console.log(info)
        var rankArr = []
					 // 数字0开头
					  var zeroArr = info.filter((ele, index) => {
					      if (Number(ele[name].substr(0, 1)) === 0) {
					          return !isNaN(Number(ele[name].substr(0, 1)))
					       }
					   })
					 // 数字0开头排序
					 zeroArr = zeroArr.sort((a, b) => {
					 // return parseInt(a.name) - parseInt(b.name)
					     return a[name].replace(/[^0-9]/ig, '') - b[name].replace(/[^0-9]/ig, '')
					  })
					 rankArr = zeroArr
					 // 数字非0开头
        var numArr = info.filter((ele, index) => {
					   if (Number(ele[name].substr(0, 1)) !== 0) {
					      return !isNaN(Number(ele[name].substr(0, 1)))
					   }
					 })
        // 数字非0开头排序
        numArr = numArr.sort((a, b) => {
					     return a[name].replace(/[^0-9]/ig, '') - b[name].replace(/[^0-9]/ig, '')
					 })
					 // 汉字开头
					 var reg = new RegExp('^[\u4e00-\u9fa5]')
					    var wordArr = info.filter((ele, index) => {
					        return reg.test(ele[name].substr(0, 1))
					    })
        // 汉字开头排序
        wordArr = wordArr.sort((a, b) => {
					     return a[name].localeCompare(b[name])
        })
        // 大写字母开头
        var regUpper = /^[A-Z]+$/
        var upperArr = info.filter((ele, index) => {
					     return regUpper.test(ele[name].substr(0, 1))
        })
        // 大写字母开头排序
        upperArr = upperArr.sort((a, b) => {
					    return a[name].localeCompare(b[name])
        })
        // 剩余其他的开头
        var otherArr = info.filter((ele, index) => {
					    return isNaN(Number(ele[name].substr(0, 1))) && !reg.test(ele[name].substr(0, 1)) && !regUpper.test(ele[name].substr(0, 1))
        })
        // 剩余其他的开头排序
					 otherArr = otherArr.sort((a, b) => {
					 return a[name].localeCompare(b[name])
        })
        // console.log(wordArr)
        // console.log(rankArr)
        // console.log(numArr)
        // console.log(upperArr)
        // console.log(otherArr)
        if (lsatSH) {
          info = wordArr
          info = info.concat(rankArr, numArr, upperArr, otherArr)
        } else {
          info = otherArr
          info = info.concat(upperArr, numArr, wordArr, rankArr)
        }
        return info
      }

      if (type == 1) {
        this.yuansiData.forEach(item => {
          item.dateTimeMS = (new Date(item.registerTime)).getTime()
        })
      } else if (type == 2) {
        this.yuansiData.forEach(item => {
          item.dateTimeMS = (new Date(item.expireTime)).getTime()
        })
      } else if (type == 3) {
        this.yuansiData.forEach(item => {
          item.dateTimeMS = item.lastDays
        })
      } else if (type == 4) {
        this.serviceData = hybirdSort(this.yuansiData, 'ownSpaceName', this.lsatSH)
        console.log(this.serviceData)
      } else if (type == 5) {
        this.yuansiData.forEach(item => {
          item.dateTimeMS = item.statu
        })
      } else if (type == 6) {
        this.yuansiData.forEach(item => {
          item.dateTimeMS = item.size
        })
      } else if (type == 7) {
        this.yuansiData.forEach(item => {
          item.dateTimeMS = item.loginTimeSort
        })
      } else {
        return
      }
      if (type != 4) {
        if (this.lsatSH) {
          this.serviceData = this.yuansiData.sort(compare('dateTimeMS'))
          // console.log(this.serviceData)
        } else {
          this.serviceData = this.yuansiData.sort(compare2('dateTimeMS'))
          // console.log(this.serviceData)
        }
      }
      this.lsatSH = !this.lsatSH
      this.pageChange(1)
    }
  }
}
</script>
