<template>
	<div class="table-box">
		<!-- 头部功能区 -->
		<div class="header-box">
			<div class="header-left">
			  <p class="el-dropdown-link changeTable" @click="back">
			  	{{tablename}}
			  </p>
			</div>
			<div class="header-right">
				<div class="hdBtnBox" style="display: flex; margin-left: 10px;">
					<el-button type="primary" @click="selectTemplate">加载样表</el-button>
					<el-button type="primary" @click="changeFloorData">修改楼层</el-button>
					<el-button type="primary" @click="addTemplate">另存样表</el-button>
					<el-button type="primary" @click="deriveExcelTable" v-if="action.exporttable">表单导出</el-button>
					<el-button type="primary" @click="importexcel" v-if="action.importtable">表单导入</el-button>
					<el-button type="primary" @click="pullUserTable">邀请成员</el-button>
					<el-button type="primary" @click="save">保存</el-button>
					<!-- <el-button type="primary" @click="getUpdate" >获取更改</el-button> -->
				</div>
			</div>
		</div>
		<!-- 表格区域 -->
		<div class="table-container1">
			<p v-show="isUpdate" style="color: red;text-align: left;margin-bottom: 10px;">您有未提交的更改记录<u style="color: #000000;margin-left: 40px;cursor: pointer;" @click="save">请保存</u></p>
			<p v-show="!isUpdate" style="color: transparent;text-align: left;margin-bottom: 10px;">1</p>
			<!-- 有编辑权限时 -->
			<!-- :cell-style="setColor"  变色的代码，暂时没用到 -->
			<el-table  ref="table" height="628px" border :data="tableDataPag"  >
				<!-- 右侧参数列 -->
				<el-table-column  v-for="(col,index) in headers" :key="Math.random()" :label="col.label"  :min-width="flexColumnWidth(col.label)">
					<!-- 使用插槽自定义头部 -->
					<template slot="header" slot-scope="scope">
						<template v-if="col.prop != 'field4'&&col.prop != 'field6'">
							<div class="head-box" style="display: block;">
								<!-- @click="updateHd($event,col.label,index)" -->
								<p  style="min-height: 20px;width: 100%;" class="header-container">
								<span><i>{{col.label}}</i><i class="el-icon-sort" @click="sortColumn(col)"></i></span>
								<!-- 删除表单列要判断是第几列、是否有删除权限，是否不是新增列 -->
								<span v-if="action.deletetablecol&&col.prop!='field1'"  class="el-icon-close closeBtn" @click.stop="deleteCol(index,col)"></span>
								</p>
							</div>
							<div style="display: none;">
								<div class="hdUpadte" style="display: flex;">
									<el-input  size="mini" v-model="headValue" @blur="sureHead(0,$event,index)"></el-input>
									<el-button size="mini" icon="el-icon-check" circle></el-button>
								</div>
							</div>
						</template>
						<!-- 单体和构建编码有下拉选择，所以拿出来单独写 -->
						<!-- 单体 -->
						<div class="head-box" v-if="col.prop == 'field4'">
							<el-dropdown trigger="click" @command="changeMonomer">
								<span class="el-dropdown-link" style="color: #000000;font-size: 17px;font-weight: bold;">{{col.label}}<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
								    <el-dropdown-item v-for="item in monomerList" :key="item.Id" :command="{par:item,label:col.label}">
										<p>{{item.monomerName}}</p>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							<span v-if="action.deletetablecol"  class="el-icon-close closeBtn" @click.stop="deleteCol(index,col)"></span>
						</div>
						<!-- 构建编码 -->
						<div class="head-box" v-if="col.prop == 'field6'">
							<el-dropdown trigger="click" @command="changeDrop">
								<span class="el-dropdown-link" style="color: #000000;font-size: 16px;font-weight: bold;">{{col.label}}<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-for="item in options" :key="item.Id"  :command="{item:item,label:col.label}">
										<p>{{item.excelName}}</p>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							<span v-if="action.deletetablecol"  class="el-icon-close closeBtn" @click.stop="deleteCol(index,col)"></span>
						</div>
					</template>
					<!--表内内容-->
					<template slot-scope="scope">
						<!-- 通过点击表格内内容实现显示输入框修改数据 -->
						<template v-if="col.prop != 'field5'&&col.prop != 'field1' &&col.prop != 'field2' &&col.prop != 'field3'">
							<div v-if="changeRow==scope.$index&&changeColumn==index" >
								<div style="display: flex;" class="tableC">
									<el-input ref="inputRef" size="mini" v-model="inputValue" @blur="sureMth(scope,col.prop,index)"></el-input>
									<el-button size="mini" icon="el-icon-check" circle @click="sureMth(scope,col.prop,index)"></el-button>
								</div>
							</div>
							<p v-else style="width: 100%;height:100%;min-height:20px;" @click="update(scope,index,scope.row[col.prop])">{{scope.row[col.prop]}}</p>
						</template>
						<template v-if="col.prop == 'field1'">
							<p style="width: 100%;height:100%;min-height:20px;" >{{scope.row[col.prop]}}</p>
						</template>
						<template v-if="col.prop == 'field3'">
							<p style="width: 100%;height:100%;min-height:20px;" >{{scope.row[col.prop]}}</p>
						</template>
						<template v-if="col.prop == 'field2'">
							<p style="width: 100%;height:100%;min-height:20px;" >{{scope.row[col.prop]}}</p>
						</template>
						<template v-if="col.prop == 'field5'">
							<el-dropdown trigger="click">
								<span class="el-dropdown-link">{{floorDataObj[scope.row[col.prop]]}}<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-for="item in floorDataList" :key="item.Id">
										<p @click="sureMth(scope,col.prop,index,item)">{{item.floorName}}</p>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</template>

				</el-table-column>
				<!-- 最右侧添加栏 -->
				<el-table-column v-if="action.addtablecol" fixed="right"  :width="widthHB" prop="date" :render-header="renderHeader"></el-table-column>
			</el-table>
			 <div class="pag-container">
			    <el-pagination
			      @size-change="handleSizeChange"
			      @current-change="handleCurrentChange"
			      :current-page.sync="currentPage"
			      :page-size="tablePageSize"
			      layout="total,sizes,prev, pager, next,jumper"
				  :page-sizes="[50, 150, 200]"
			      :total="tableTotal">
			    </el-pagination>
			  </div>
		</div>
		<!-- 导入弹出框 -->
		<el-dialog
		  title="导入"
		  :visible.sync="importexcelDia"
		  :close-on-click-modal="false"
		  @close="importClose"
		  width="30%">
		  <el-upload
		    class="upload-demo"
		    drag
			:on-remove="deleteFile"
			accept=".xls,.xlsx"
			:before-upload="beforeUpload"
		    action :http-request="upload" :limit="1" :file-list="filesList"
		    multiple>
		    <i class="el-icon-upload"></i>
		    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
		    <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
		  </el-upload>
		  <div class="dia-btn" style="display: flex;justify-content: flex-end;">
			  <el-button type="primary" @click="importMth">确认</el-button>
		  </div>
		</el-dialog>
		<!-- 选择样表的弹出框 -->
		<div class="transfer-continaer" v-if="previewTransfer">
		<el-dialog
		  title="加载数据规格库"
		  :visible.sync="previewTransfer"

		  :close-on-click-modal="false"
		  width="50%">
		  	<!-- 弹出的组件 -->
			  <complexTransfer :codes="fixCode" :data="standardList" @templateSure="templateSure"></complexTransfer>
		</el-dialog>
		</div>
		<el-dialog :visible.sync="copyVisible" :close-on-click-modal="false">
			<el-form :model="ruleFormCopy" :rules="rulesCopy" ref="ruleFormCopy" label-width="130px" class="demo-ruleForm">
				<el-form-item :label="$t('property.sampleName')" prop="name">
					<el-input v-model="ruleFormCopy.name"></el-input>
				</el-form-item>
				<el-form-item :label="$t('property.copyLocation')" style="text-align: left;">

					 <el-radio-group @change="changeTarget" v-model="radio">
					    <el-radio v-if="isToSelecet.common"  v-model="radio" :label="0">{{$t('property.publicSpace')}}</el-radio>
					    <el-radio v-if="isToSelecet.space"  v-model="radio" :label="1">{{$t('property.enterprise')}}</el-radio>
					    <el-radio v-if="isToSelecet.project"  v-model="radio" :label="2">{{$t('property.property')}}</el-radio>
					  </el-radio-group>
				</el-form-item>
				<div class="inline-box" >
					<el-form-item v-if="radio==1||radio==2" :label="$t('property.targetEnterprise')" prop="spaceId">
						<el-select :disabled="isDisabled" v-model="ruleFormCopy.spaceId" :placeholder="$t('common.pleaseChoose')+$t('property.targetEnterprise')" @change="toSpceChange">
							<el-option v-for="item in toSpaceList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div class="inline-box">
					<el-form-item v-if="radio==2" :label="$t('property.targetItem')" prop="projectId">
						<el-select v-model="ruleFormCopy.projectId" :placeholder="$t('common.pleaseChoose')+$t('property.targetItem')" @change="toProjectChange">
							<el-option v-for="item in toProjectList" :key="item.id" :label="item.projectName" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div class="inline-box">
					<el-form-item  :label="$t('property.folder')" prop="floderId">
						<el-select v-model="ruleFormCopy.floderId" :placeholder="$t('common.pleaseChoose')+$t('property.folder')">
							<el-option v-for="item in toFloderList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
			</el-form>
			<p style="text-align: right;">
				<el-button type="primary" icon="el-icon-success" @click="addToTarget('ruleFormCopy')">{{$t('common.confirmation')}}</el-button>
			</p>
		</el-dialog>
		<ChangeFloor :tableData="floorDataList" :mappingtable="showViewId" ref="changeFloor" @changeFloorDataWeb="changeFloorDataWeb" />
		<add-user ref="addUser" :userTableList="userTableList" :mappingtable="showViewId" :userSource = "userSource" :userValueC="userValue"></add-user>
	</div>
</template>
<script>
import { mapState } from 'vuex'
import complexTransfer from '@/components/complexTransfer.vue'
import AddUser from '@/components/addUser.vue'
import ChangeFloor from '@/components/ChangeFloor.vue'
import { deriveExcel } from '@/mixins/deriveExcel'
import { share } from '@/mixins/share'
export default {
  props: ['tableGuidC', 'tablenameC', 'highElementIds', 'tables', 'codes'],
  mixins: [deriveExcel, share],
  components: {
    complexTransfer, AddUser, ChangeFloor
  },
  data () {
    return {
      changeRow: -1,
      changeColumn: -1,
      tableGuid: '',
      tablename: '',
      tableTotal: 0,
      tablePageSize: 50,
      currentPage: 1,
      isGetUpdate: false, // 控制是否从新获取更新的数据 ，只有点击了获取更新按钮之后才会在换页的时候触发
      filesList: [],
      importexcelDia: false,
      tableData: [], // 原始的表格数据
      tableDataPag: [], // 分页之后的表格数据
      newTableData: [], // 备份的表格数据
      datas: {
        'tablename': '表名称（族类别）',
        'tabledata': []
      },
      headers: [],
      addBtn: true,
      addValue: '',
      yuansiLength: 0,
      inputValue: '',
      yuansiValue: '',
      widthHB: 50,
      options: ['水石BIM分类编码标准'],
      value: '水石BIM分类编码标准',
      headersOne: [],
      headValue: '',
      editRow: {},
      deleteColList: [],
      deleteNameList: [], // 删除时保存一份被删除列的名字，用于再次添加的时候进行过滤
      yuansiHeaders: [],
      yuansiMax: 0,
      yuansiAdd: 1,
      yuansiHeadersLenght: 0,
      headerInfo: {},
      indexNum: 0, // 用来修改索引
      addList: [], // 用来保存新增的列的prop
      standardList: [],
      isUpdate: false, // 用于标识是否对表格进行了修改
      getrowColor: [],
      getcolumnColor: [],
      rowColor: [],
      columnColor: [],
      columnuName: '',
      highRows: [],
      changeRecord: {},
      isEdit: false, // 用作判断是否更改了单元格内容的开关
      monomerList: [], // 单体
      projectname: '', // 项目名
      previewTransfer: false, // 选择样表的弹出框
      serialNumber: [], // 流水号列
      addFixHeaderList: [],
      fixCode: {},
      lastSH: true,
      dialogUserTable: false,
      userTableList: [],
      filterMethod (query, item) {
				  return item.label.indexOf(query) > -1
      },
      userValue: [],
      userSource: [],
      addTemplateList: [],
      floorDataList: [],
      floorDataObj: {},
      headerCopy: [], // 总的头部，包括没显示的类容
      fixHeaders: [], // 固定列的表头名字
      addDataList: [], // 增加的列
      headersCopy: [],
      specialCode_RevitDefaultParameters: [],
      allElementIDList: []
    }
  },
  created () {
    this.tableGuid = this.tableGuidC || localStorage.getItem('tableGuidC')
    if (!this.tablenameC) {
      this.tablename = localStorage.getItem('tablename')
    } else {
      this.tablename = this.tablenameC
    }
    this.fixCode = this.codes || JSON.parse(localStorage.getItem('fixCode'))
    // if(!this.tableGuidC){}
    this.getSpecialCode()
    this.getData()
    this.getStandardList()
    this.projectname = localStorage.getItem('projectName')
    this.getCodeList()
    this.initFloor()
  },
  mounted () {
    // 监听浏览器关闭事件和刷新事件，浏览器关闭时保存一下
    window.addEventListener('beforeunload', () => {
      if (this.isUpdate) {
        this.save(1)
      }
    })
    this.getMonomerCodeAndFloorCode()
    // 初始化楼层选项
  },
  // 监听组件销毁，浏览器关闭的时候不会触发
  destroyed () {
    if (this.isUpdate) {
      this.save(1)
    }
  },
  computed: {
		  ...mapState('action', {
		    action: 'action'
		  }),
		  ...mapState('tableShow', {
		  	showViewId: 'showViewId'
		  }),
		  ...mapState('tableShow', {
		  	createUserId: 'createUserId'
		  })
  },
  methods: {
    getSpecialCode () {
      this.$axios.get('/api/formhandle/getspecialcode').then(res => {
        this.specialCode_RevitDefaultParameters = res.specialCode_RevitDefaultParameters
      })
    },
    // 导出table
    deriveExcelTable () {
      const data = {
        mappingTable: this.tableGuid,
        tableName: this.tablename,
        floors: this.floorDataList
      }
      this.deriveExcel('/api/formhandle/exportexcel', data, '.xlsx')
    },
    // 上传文件时的删除文件
    deleteFile () {
      this.filesList = []
    },
    // 自适应表头宽度
    flexColumnWidth (name) {
      let nameArr = name.split('')
      let flexWidth = 0
      for (let char of nameArr) {
        if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z')) {
          // 如果是英文字符，为字符分配8个单位宽度
          flexWidth += 10
        } else if (char >= '\u4e00' && char <= '\u9fa5') {
          // 如果是中文字符，为字符分配15个单位宽度
          flexWidth += 18
        } else {
          // 其他种类字符，为字符分配8个单位宽度
          flexWidth += 9
        }
      }
      flexWidth += 70
      if (flexWidth < 80) {
        // 设置最小宽度
        flexWidth = 80
      }
      return flexWidth + 'px'
    },
    // 换一张表
    async changeTable (item) {
      // 'tableGuid','tablename','highElementIds','tables
      if (item.tablename == this.tablename) {
        this.$message({
					    message: '已处于该表格',
					    type: 'warning'
					 })
        return
      }
      await this.save(1)
      this.tableGuid = item.mappingtable
      this.tablename = item.tablename
      this.getData()
    },
    // 套用参数样表的确认时间
    templateSure (data) {
      // 1.先找出代表前六列的数据，即id小于0的
      const len = data.length
      data = data.filter(item => {
        return this.headers.findIndex(item2 => item2.label === item.name) == -1
      })
      if (data.length != len) {
        this.$message({
					    message: '发现重复列，已自动过滤',
					    type: 'warning'
        })
      }
      let fixArr = data.filter(item => {
        return item.id < 0
      })
      if (fixArr.length) {
        this.isUpdate = true
      }

      for (let item of fixArr) {
        // 找出在固定列中的顺序，是第几个
        const index = this.fixHeaders.findIndex(e => e == item.name)
        const data = {
          prop: `field${index}`,
          label: item.name
        }
        var k = 0
        for (let i = 0; i < this.headers.length; i++) {
          if (this.headers[i + 1]) {
            if (index > this.headers[i].prop.slice(5) && index < this.headers[i + 1].prop.slice(5)) {
              k = i + 1
              break
            }
          } else {
            k = this.headers.length
          }
        }
        this.headers.splice(k, 0, data)
        if (this.headersCopy.findIndex(e => e == item.name) != -1) {
          // 找到有没有在删除的数据中，有的话需要走删除中移除
          const ind = this.deleteNameList.findIndex(a => a == item.name)
          this.deleteColList.splice(ind, 1)
          this.deleteNameList.splice(ind, 1)
        } else {
          this.addFixHeaderList.push(item.name)
        }

        // 是添加企业编码的时候去生成企业编码列
        if (index == 2) {
          this.autoAddSpaceCodeAndProjectCode(3)
        }
        // 是添加项目编码的时候去生成项目编码列
        if (index == 3) {
          this.autoAddSpaceCodeAndProjectCode(4)
        }
      }
      // 对非固定六列执行添加操作
      let addList = data.filter(item => {
        return item.id >= 0 || item.id <= -9
      })
      if (addList.length != 0) {
        this.isUpdate = true
      }
      for (let item of addList) {
        let name = item.name
        if (this.headersCopy.findIndex(e => e == name) != -1) {
          // 找到有没有在删除的数据中，有的话需要走删除中移除
          const ind = this.deleteNameList.findIndex(a => a == name)
          this.deleteColList.splice(ind, 1)
          this.deleteNameList.splice(ind, 1)
        } else {
          let fie = 'field' + (Number(this.yuansiMax) + Number(this.yuansiAdd))
          this.headers.push({
            // 因为最前面有一列被单独拿走，所以要加2
            prop: fie,
            label: name
          })
          this.addList.push(fie)
          this.addDataList.push(name)
          this.headerInfo[fie] = [name, this.specialCode_RevitDefaultParameters]
          this.yuansiAdd += 1
          this.indexNum += 1
        }
      }
      this.previewTransfer = false
    },
    // 选择样表
    selectTemplate () {
      this.previewTransfer = true
    },
    // 清除缓存
    clearCache () {
      this.getData('clear')
    },
    changeFloorDataWeb (item) {
      const ind = this.floorDataList.findIndex(e => e.floorId == item.floorId)
      const data = this.floorDataList[ind]
      data.floorName = item.floorName
      this.$set(this.floorDataList, ind, data)
      this.floorDataList.forEach(item => {
        this.floorDataObj[item.floorId] = item.floorName
      })
    },
    // 自动添加企业编码和项目编码
    autoAddSpaceCodeAndProjectCode (type) {
      var code = null
      if (type == 3) {
        code = localStorage.getItem('enterpriseCode')
      } else {
        code = localStorage.getItem('projectCode')
      }
      let flag = this.tableData[0][`field${type}`] == '' || this.tableData[0][`field${type}`] != code
      // 判断一下是不是空的,或者是不是错的
      if (flag) {
        this.tableData.forEach(item => {
          // return
          item[`field${type - 1}`] = code
          // item.field3 = projectCode
        })
        var data = this.tableData
        for (let items of data) {
          let item = JSON.parse(JSON.stringify(items))
          let aid = item.field1
          if (this.editRow[aid]) {
            // 如果存在更新数据
            if (this.editRow[aid].updataOldCol) {
              this.editRow[aid].updataOldCol[type] = {
                keyNumber: `${type}`,
                value: code
              }
            } else {
              this.editRow[aid].updataOldCol[type] = {
                keyNumber: `${type}`,
                value: code
              }
            }
          } else {
            // 如果该行数据不存在
            this.editRow[aid] = {
              elementId: aid,
              updataOldCol: {
                [type]: {
                  keyNumber: `${type}`,
                  value: code
                }
              }
			 	}
			 }
			 this.isUpdate = true
        }
      }
    },
    // 获取单体和楼层
    getMonomerCodeAndFloorCode () {
      let pid = localStorage.getItem('eleProjectId')
      this.$axios.get(`/api/project/getprojectbyid?projectId=${pid}`).then(res => {
        let list = res.comDtoList
        let parList = []
        for (let item of list) {
          let find = parList.find(one => {
            return one.monomerCode == item.monomerCode
          })
          // 如果没找到
          if (!find) {
            let obj = {
              id: item.id,
              monomerCode: item.monomerCode,
              monomerName: item.monomerName,
              children: []
            }
            parList.push(obj)
          }
        }
        for (let ind in parList) {
          let childList = list.filter(item => {
            return parList[ind].monomerCode == item.monomerCode
          })
          parList[ind].children = childList
        }
        this.monomerList = parList
        // 初始化楼层的选择项
        // this.initFloor()
      })
    },

    handleSizeChange (val) {
      this.tablePageSize = val
      this.handleCurrentChange(this.currentPage)
    },
    // 当前页码更换的时候
    handleCurrentChange (val) {
      this.tableDataPag = this.tableData.slice((val - 1) * this.tablePageSize, val * this.tablePageSize)
			    this.tableDataPag.forEach(item => {
        if (item.field2) {
          item.field2 = localStorage.getItem('enterpriseCode')
        }
        if (item.field3) {
          item.field3 = localStorage.getItem('projectCode')
        }
      })
      // this.$refs.table.doLayout()
      // 如果已经获取了最新的更改记录，每次都需要判断当前页的数据有没有被包含在更改记录中
      // if(this.isGetUpdate){
      // 	this.newestChangeColor()
      // }
      // this.highRow()
      // 判断是否有单个的更改记录
      // if(this.isEdit){
      // 	// return
      // 	 this.tableCellChangeColor()
      // }
      setTimeout(() => {
        this.$nextTick(() => {
					      this.$refs.table.doLayout()
        })
      }, 1000)
      // this.$nextTick(()=>{
      //       this.$refs.table.doLayout()
      // })
    },
    // 加载上一次未保存的修改记录并执行相关操作
    // async noSave(){
    // 	let data = JSON.parse(localStorage.getItem(this.tableGuid))

    // 	if(data){
    // 		if(this.tableGuid !=data.mappingTable){
    // 			return
    // 		}
    // 		// 如果存在未保存的数据
    // 		this.$confirm('发现改项目中上次未保存的表单更改，是否保存更改?', '提示', {
    // 		          confirmButtonText: '确定',
    // 		          cancelButtonText: '取消',
    // 		          type: 'warning'
    // 		        }).then(() => {
    // 					this.editRow = data.data
    // 					this.isUpdate = true
    // 					this.save()
    // 		        }).catch(() => {
    // 					this.$confirm('是否删除临时更改记录?', '提示', {
    // 					          confirmButtonText: '确定',
    // 					          cancelButtonText: '取消',
    // 					          type: 'warning'
    // 					        }).then(() => {
    // 								localStorage.removeItem(this.tableGuid);
    // 					          this.$message({
    // 					            type: 'success',
    // 					            message: '删除成功!'
    // 					          });
    // 					        }).catch(() => {
    // 					          this.$message({
    // 					            type: 'info',
    // 					            message: '已取消删除'
    // 					          });
    // 					        });
    // 		        });
    // 	}
    // },
    // async autoSave(){
    // 	// 如果对表格存在修改
    // 	if(this.isUpdate){
    // 		localStorage.setItem('isFix2','修改了2')
    // 		const data = this.editRow
    // 		const eleProjectId = localStorage.getItem('eleProjectId')
    // 		const eleSpaceId = localStorage.getItem('eleSpaceId')
    // 		const mappingTable = this.tableGuid
    // 		let allData = {
    // 			data:data,
    // 			tablename:this.tablename,
    // 			mappingTable:this.tableGuid,
    // 			eleProjectId:eleProjectId,
    // 			eleSpaceId:eleSpaceId
    // 		}
    // 		localStorage.setItem(mappingTable,JSON.stringify(allData))
    // 	}
    // },
    // 对某一行进行变色操作，暂时没用到
    highRow () {
      var indexs = []
      for (let index in this.tableDataPag) {
        let ind = this.highElementIds.findIndex(item => {
          return item == this.tableDataPag[index].field1
        })
        if (ind != -1) {
          indexs.push(Number(index))
        }
      }
      this.highRows = indexs
    },
    getCodeList () {
      let sid = localStorage.getItem('eleSpaceId')
      this.$axios.get(`/api/formhandle/getcodeliblist?SpaceId=${sid}`).then(res => {
        res.forEach(item => {
          this.options.push(...item.codingListDto)
        })
      })
    },
    // 获取构建编码
    getCoding (item, label) {
      const index = this.headerCopy.findIndex(e => e == label)
      let codingStandardUrl = item.excelUrl
      let matches = item.matches
      if (!matches) {
        matches = ''
      }
      this.$axios.get(`/api/formhandle/componentcoding?mappingTable=${this.tableGuid}&codingStandardUrl=${codingStandardUrl}&matches=${matches}`).then(res => {
        if (!Array.isArray(res) || res.length == 0) {
          this.$message({
            showClose: true,
            message: '当前暂无构件编码',
            type: 'warning'
          })
          return
        }
        this.tableData.forEach(item => {
          let obj = res.filter(item2 => {
            return item2.elementID == item.field1
          })
						 if (obj.length) {
            item[`field${index}`] = obj[0].componentCoding
            item[`field${index + 1}`] = obj[0].serialNumber
          }
        })
        this.dataCreate(index)
      })
    },
    // 返回操作
    back () {
      this.$parent.reback()
    },
    // 导出excel
    // 判断是否有格子更改的变色，主要是用在刚加载table等一些清空下，该功能暂时未被使用
    tableCellChangeColor (isLoad) {
      // 用于还原没有保存的更改记录时对新增的数据进行操作
      this.yuansiLength = this.yuansiHeaders.length - 7 // 重置原始长度，避免重复获取时一直减小,因为最开始有三列固定列不受影响，他们没有被算到长度中，所以要减去7
      this.getrowColor = []
      this.getcolumnColor = []
      this.rowColor = []
      this.columnColor = []
      let rowIndex = 0
      const cloneObj = JSON.parse(JSON.stringify(this.editRow))
      var fixData = Object.values(cloneObj)
      fixData = fixData.filter(item => {
        return item.updataOldCol
      })
      if (fixData.length == 0) {
        return
      }
      for (var key in fixData) {
        fixData[key].updataOldCol = Object.values(fixData[key].updataOldCol)
      }
      for (var key in this.tableDataPag) {
        let find = fixData.find(item => {
          return item.elementId == this.tableDataPag[key].field1
        })
        // 判断是否有这一行的数据
        if (find) {
          // 判断这一行数据是否被更新
          if (find.updataOldCol) {
            // 这里进行修改
            for (var arrupdate of find.updataOldCol) {
              let data = this.tableDataPag[key]
              let flag = 1
              var fie = ''
              for (var field in this.tableDataPag[key]) {
                if (flag == arrupdate.keyNumber) {
                  if (isLoad) {
                    this.tableDataPag[key][field] = arrupdate.keyNumber
                  }
                  this.rowColor.push(rowIndex)
                  this.columnColor.push(flag - 1)
                }
                flag++
              }
            }
          }
        }
        // }
        rowIndex++
      }
    },
    // 点修改楼层按钮之后弹出弹出框
    changeFloorData () {
      this.$refs['changeFloor'].changeFloorDia = true
    },
    // 让最新更改变色，暂时未用到
    newestChangeColor () {
      this.yuansiLength = this.yuansiHeaders.length - 7 // 重置原始长度，避免重复获取时一直减小,因为最开始有三列固定列不受影响，他们没有被算到长度中，所以要减去7
      let res = this.changeRecord
      this.isGetUpdate = true
      // 重置一下使得不保存上一次的修改
      this.getrowColor = []
      this.getcolumnColor = []
      let fixData = []
      var addNewCol = []
      fixData = res.handleData
      if (res.importTableMeta) {
        addNewCol = res.importTableMeta.addTableHeaderName
      }
      let rowIndex = 0
      for (var key in this.tableDataPag) {
        let find = fixData.find(item => {
          return item.elementId == this.tableDataPag[key].field1
        })
        if (find) {
          // 判断这一行数据是否被更新
          if (find.updataOldCol) {
            // 这里进行修改
            for (var arrupdate of find.updataOldCol) {
              let data = this.tableDataPag[key]
              let flag = 1
              var fie = ''
              for (var field in this.tableDataPag[key]) {
                if (flag == arrupdate[0]) {
                  // 这一行代码用来修改最新更改中改变的值，但是这个已经被修改过了，就不需要了
                  // this.tableDataPag[key][field] = arrupdate[1]
                  this.getrowColor.push(rowIndex)
                  this.getcolumnColor.push(flag - 1)
                }
                flag++
              }
            }
          }
        }
        // }
        rowIndex++
      }
      // 通过改变原始长度来找出新增列
      this.yuansiLength = this.yuansiLength - addNewCol.length + 1
      // 新增只用拿一次。所以不用循环
      // 判断是否有新增，如果有就先改变头部再改变数据
    },
    // 获取最新更改,然后进行变色操作，该功能暂时取消。
    async getUpdate (type) {
      if (this.isUpdate) {
        this.$message({
          showClose: true,
          message: '请先保存当前更改！',
          type: 'warning'
        })
        return
      }
      // 打开开关使得换页的时候能够执行对应的变色方法
      this.isGetUpdate = true
      await this.$axios.get(`/api/formhandle/gettableupdatedata?mappingTable=${this.tableGuid}`).then(res => {
        if (res == '当前表暂无更改记录') {
          this.$message({
            showClose: true,
            message: res,
            type: 'warning'
          })
        } else {
          let newArr = res.importTableMeta.addTableHeaderName
          let { spaceCode, projectCode, monomer, floor, componentCode, serialNumber } = this.fixCode
          newArr = newArr.filter(item => {
            return item != spaceCode && item != projectCode && item != monomer && item != floor && item != componentCode && item != serialNumber
          })
          res.importTableMeta.addTableHeaderName = newArr
          this.changeRecord = res
          this.newestChangeColor()
          this.importexcelDia = false
          this.$message({
            showClose: true,
            message: '已成功获取当前表更改记录',
            type: 'success'
          })
        }
      })
    },
    // 导入
    importexcel () {
      this.importexcelDia = true
      this.rowColor = []
      this.columnColor = []
    },
    // 导出文件
    upload (file) {
      this.filesList.push({
        name: file.file.name,
        singlefile: file.file,
        url: URL.createObjectURL(file.file)
      })
    },
    // 导入框关闭的时候清空文件列表
    importClose () {
      this.filesList = []
    },
    // 限制文件类型
    beforeUpload (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (fileSuffix != 'xls' && fileSuffix != 'xlsx') {
        this.$message.error('请上传excel文件')
        return false
      }
    },
    // 确认导入
    async importMth () {
      let formData = new FormData()
      formData.append('tableName', this.tablename)
      formData.append('mappingTable', this.tableGuid)
      formData.append('formFile', this.filesList[0].singlefile)
      for (let i in this.floorDataList) {
        for (let k in this.floorDataList[i]) {
          formData.append(`floors[${i}].${k}`, this.floorDataList[i][k])
        }
      }
      await this.$axios.post('/api/formhandle/importexcel', formData).then(res => {
        // if(res=='上传成功'){
        this.$message({
          showClose: true,
          message: res,
          type: 'success'
        })
        this.getData()
        // }
        this.importexcelDia = false
        // 改变成功之后清空上传的文件列表
        this.filesList = []
      })
    },
    // 生成构建编码中的数据
    dataCreate (index) {
      index = index
      var data = this.tableData
      for (let items of data) {
        let item = JSON.parse(JSON.stringify(items))
        let aid = item.field1
        if (this.editRow[aid]) {
          // 如果存在更新数据
          if (this.editRow[aid].updataOldCol) {
            this.editRow[aid].updataOldCol[index + 1] = {
              keyNumber: `${index + 1}`,
              value: item[`field${index}`]
            }
            this.editRow[aid].updataOldCol[index + 2] = {
              keyNumber: `${index + 2}`,
              value: item[`field${index + 1}`]
            }
          } else {
            this.editRow[aid].updataOldCol[index + 1] = {
              keyNumber: `${index + 1}`,
              value: item[`field${index}`]
            }
            this.editRow[aid].updataOldCol[index + 2] = {
              keyNumber: `${index + 2}`,
              value: item[`field${index + 1}`]
            }
          }
        } else {
          // 如果该行数据不存在
          this.editRow[aid] = {
            elementId: aid,
            updataOldCol: {
              [index + 1]: {
                keyNumber: `${index + 1}`,
                value: item[`field${index}`]
              },
              [index + 2]: {
                keyNumber: `${index + 2}`,
                value: item[`field${index + 1}`]
              }
            }
          }
        }
      }
      this.isUpdate = true
    },
    // 构件分类编码改变
    changeDrop (data) {
      const item = data.item
      const label = data.label
      this.getCoding(item, label)
    },
    // 单体改变
			 changeMonomer (data) {
				 const par = data.par
				 const label = data.label
				 const index = 5
				 // 2.修改table中的数据
				 this.tableData.forEach(item => {
				 	item[`field${index - 1}`] = par.monomerCode
				 })
				 this.handleCurrentChange(1)
				 // 3.修改更改记录
				 var data = JSON.parse(JSON.stringify(this.tableData))
				 for (let items of data) {
				 	let item = JSON.parse(JSON.stringify(items))
				 	let aid = item.field1
				 	if (this.editRow[aid]) {
				 		// 如果存在更新数据
				 		if (this.editRow[aid].updataOldCol) {
				 			this.editRow[aid].updataOldCol[index] = {
				 				keyNumber: `${index}`,
				 				value: par.monomerCode
				 			}
				 		} else {
				 			this.editRow[aid].updataOldCol[index] = {
				 				keyNumber: `${index}`,
				 				value: par.monomerCode
				 			}
				 		}
				 	} else {
				 		// 如果该行数据不存在
				 		this.editRow[aid] = {
				 			elementId: aid,
				 			updataOldCol: {
				 				[index]: {
				 					keyNumber: `${index}`,
				 					value: par.monomerCode
				 				}
				 			}
				 		}
				 	}
				 }
				 this.isUpdate = true
			 },
			 // 初始化楼层选项
			 initFloor () {
				 this.$axios.get(`/api/formhandle/gettablefloor?mappingTable=${this.tableGuid}`).then(res => {
					 this.floorDataList = res
					 res.forEach(item => {
						 this.floorDataObj[item.floorId] = item.floorName
					 })
				 })
			 },
    // 头部修改，这里只是点击之后触发输入框
    updateHd (e, hvalue, index) {
      // 现阶段只能新添加的可以修改
      if (index > this.yuansiLength) {
        if (e.path[0].tagName == 'SPAN') {
          e.path[3].children[1].style.display = 'block'
          e.path[3].children[0].style.display = 'none'
          e.path[3].children[1].children[0].children[0].children[0].focus()
          this.headValue = hvalue
        } else {
          e.path[2].children[1].style.display = 'block'
          e.path[2].children[0].style.display = 'none'
          e.path[2].children[1].children[0].children[0].children[0].focus()
          this.headValue = hvalue
        }
      }
    },
    // 获取标准列表
    getStandardList () {
      this.$axios.get('/api/standard/getstandardlist?type=2').then(res => {
        this.standardList = res
      })
    },
    // 点击头部得确定或者输入框失去焦点之后触发数据更新
    sureHead (type, e, index) {
      if (!type) {
        e.path[4].children[0].style.display = 'block'
        e.path[4].children[1].style.display = 'none'
        var obj = this.headers[index]
        obj.label = this.headValue
        this.$set(this.headers, index, obj)
        this.isUpdate = true
      }
    },
    // 最终提交的时候生成向后台提交的数据，调试时可在此方法下查看提交的数据
    dataMth () {
      const addHeaderList = this.addDataList
      addHeaderList.push(...this.addFixHeaderList)
      // 得到所有表内行的修改
      const handleArr = Object.values(this.editRow)
      if (handleArr.length) {
        for (var index in handleArr) {
          if (handleArr[index].updataOldCol) {
            handleArr[index].updataOldCol = Object.values(handleArr[index].updataOldCol)
            var newArr = []
            for (var item of handleArr[index].updataOldCol) {
              newArr.push(Object.values(item))
            }
            handleArr[index].updataOldCol = newArr
          } else {
            handleArr[index].updataOldCol = []
          }
          // 判断存不存在新增
          if (handleArr[index].addNewCol) {
            handleArr[index].addNewCol = Object.values(handleArr[index].addNewCol)
            var newArr = []
            for (var item of handleArr[index].addNewCol) {
              newArr.push(Object.values(item))
            }
            handleArr[index].addNewCol = newArr
          } else {
            handleArr[index].addNewCol = []
          }
        }
      }
      // 得到headerInfo
      var hInfo = Object.values(this.headerInfo)
      // 得到删除的列
      // 没有标识先置为空
      var flagRevit = false
      if (flagRevit) {
        this.$message({
          showClose: true,
          message: '族名称 revit参数设置有误',
          type: 'warning'
        })
      }
      const delOldCol = []
      const importTableMeta = {
        addTableHeaderName: addHeaderList,
        tableHeaderInfo: hInfo
      }
      if (handleArr.length == 0) {
        handleArr.push({})
      }
      // 生成最终数据
      const datas = {
        importTableMeta: importTableMeta,
        handleData: handleArr,
        delOldCol: this.deleteColList,
        delColElements: this.deleteColList.length ? this.allElementIDList : [],
        addColElements: addHeaderList.length ? this.allElementIDList : []
      }
      return datas
    },
    //  保存操作
    async save (type) {
      if (!this.isUpdate) {
        this.$message({
          showClose: true,
          message: '数据未发生修改',
          type: 'warning'
        })
        return
      }
      const data = await this.dataMth()
      let urltablename = encodeURIComponent(this.tablename)
      this.$axios.post(`/api/formhandle/updateformdata?tablename=${urltablename}&mappingTable=${this.tableGuid}`, data).then(res => {
        if (type == 1) {
          return
        }
        if (res == '更新成功') {
          // 更新成功之后初始化相关数据才能不影响下次修改
          // 重置一下使得不保存上一次的修改
          this.rowColor = []
          this.columnColor = []
          this.getrowColor = []
          this.getcolumnColor = []
          this.editRow = {}
          this.deleteColList = []
          this.allElementIDList = [] // 清空删除构件elementid
          this.addList = []
          this.changeRecord = {}
          this.addDataList = []
          this.addFixHeaderList = []
          this.deleteNameList = []
          this.headersCopy = []
          this.headerCopy = []
          this.isUpdate = false
          this.isGetUpdate = false
          this.getData()
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          })
          // this.isUpdate = false
        } else {
          this.$message({
            showClose: true,
            message: '发生错误',
            type: 'error'
          })
        }
      })
    },

    // 获取初始数据与数据处理
    getData (type) {
      this.headers = []
      this.headersOne = []
      this.tableData = []
      this.newTableData = []
      this.headerInfo = {}
      this.yuansiMax = 0
      this.yuansiHeaders = []
      this.editRow = {}
      this.isUpdate = false
      this.isGetUpdate = false
      this.getrowColor = []
      this.getcolumnColor = []
      this.rowColor = []
      this.columnColor = []
      this.addTableHeaderName = []
      this.$axios.get(`/api/formhandle/getformdata?mappingTable=${this.tableGuid}`).then(res => {
        var newObj = JSON.parse(res)
        this.columnuName = newObj[0].field6
        // 表为空就不往下继续了
        if (!newObj.length) {
          return
        }
        // 将头部分成两块，中间插入下拉选择
        var i = 0
        for (var key in newObj[0]) {
          this.headers.push({
            prop: key,
            label: newObj[0][key],
            key: i++
          })
          this.headersCopy.push(newObj[0][key])
          this.yuansiHeaders.push(key)
        }
        this.yuansiHeadersLenght = this.yuansiHeaders.length
        for (var e of Object.values(newObj[1])) {
          this.headerCopy.push(
            e[0]
          )
        }
        // 有几个固定列就取几，保存固定列
        this.fixHeaders = this.headerCopy.slice(0, 8)
        // 获取filed后面最大的值，在添加列的时候用于生成新增列的filedx，该数据仅用于前端，不会发送给后端服务器
        this.yuansiMax = this.yuansiHeaders[this.yuansiHeaders.length - 1].substring(5)
        this.tableData = newObj.slice(2)
        this.tableData.map(item => {
          this.allElementIDList.push(item.field1)
        })
        // 拿出所有的elementID
        this.headerInfo = newObj[1]
        this.tableTotal = this.tableData.length
        this.handleCurrentChange(this.currentPage)
      })
    },
    // 编辑表格的时候表格样式的变化、由显示变为输入框
    update (scope, index, value) {
      this.changeRow = scope.$index
      this.changeColumn = index
      this.yuansiValue = value
      this.inputValue = value
      let that = this
      setTimeout(() => {
        this.$refs.inputRef[0].focus()
      }, 10)
    },
    // 拿出该列索引的方法
    getIndex (value) {
      var index = this.headerCopy.findIndex(item => item == value)
      return index + 1
    },
    // 确定修改之后对数据和样式进行修改
    sureMth (scope, key, indexColumn, item) {
      if (item) {
        this.inputValue = item.floorId
      }
      const value = scope.row[key]
      const index = scope.$index
      // 样式改变为正常显示
      this.changeRow = -1
      this.changeColumn = -1
      // 如果值没有改变就直接终止
      if (value == this.inputValue) {
        return
      }
      // var keyNumber = this.getIndex(scope.column.label).toString()
      // if(keyNumber <= 0 ){
      // 	keyNumber = this.headerCopy.length + indexColumn - this.yuansiHeadersLenght+1
      // }
      var newArr = this.tableDataPag
      var obj = JSON.parse(JSON.stringify(newArr[index]))
      // 创建或者修改已经生成的数据
      // 判断数据数据是否发生改变
      var aid = scope.row.field1
      var keyNumber = Object.values(this.headerInfo).findIndex(a => a[0] == scope.column.label) + 1
      if (keyNumber <= this.headerCopy.length) {
        // zaizh
        // 此时发生了修改
        // 如果该行数据已经生成就去操作这个数据
        if (this.editRow[aid]) {
          if (this.editRow[aid].updataOldCol) {
            this.editRow[aid].updataOldCol[keyNumber] = {
              keyNumber: `${keyNumber}`,
              value: `${this.inputValue}`
            }
          } else {
            this.editRow[aid].updataOldCol = {
              [keyNumber]: {
                keyNumber: `${keyNumber}`,
                value: `${this.inputValue}`
              }
            }
          }
        } else {
          // 如果该行数据不存在
          this.editRow[aid] = {
            elementId: aid,
            updataOldCol: {
              [keyNumber]: {
                keyNumber: `${keyNumber}`,
                value: `${this.inputValue}`
              }
            }
          }
        }
        // 如果是新增的就添加到新增里面
      } else {
        // 新增列的时候索引需要判断是新增钟的第几个，用这个第几个再加上原始的数据的长度
        // 新增和修改都分为这一行的数据已经存在和不存在，已经存在的时候要看是存在新增还是修改，没有新增就添加，有新增就修改。修改数据功能实现相同
        if (this.editRow[aid]) {
          if (this.editRow[aid].addNewCol) {
            this.editRow[aid].addNewCol[keyNumber] = {
              keyNumber: keyNumber.toString(),
              value: `${this.inputValue}`
            }
          } else {
            this.editRow[aid].addNewCol = {
              [keyNumber]: {
                keyNumber: keyNumber.toString(),
                value: `${this.inputValue}`
              }
            }
          }
        } else {
          // 如果该行数据不存在
          this.editRow[aid] = {
            elementId: aid,
            addNewCol: {
              [keyNumber]: {
                keyNumber: keyNumber.toString(),
                value: `${this.inputValue}`
              }
            }
          }
        }
      }
      // 数据改变放最后
      obj[key] = `${this.inputValue}`
      this.$set(this.tableData, this.tablePageSize * (this.currentPage - 1) + index, obj)
      this.$set(this.tableDataPag, index, obj)
      this.isEdit = true
      // 采用新的表格变色方式，将elementId和列的索引存起来，然后进行变色。
      // this.tableCellChangeColor()
      this.isUpdate = true
    },
    // 删除某一列
    deleteCol (ind, col) {
      this.deleteNameList.push(col.label)
      const index = this.getIndex(col.label)
      this.$confirm('此操作将删除该列数据，是否继续?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
        // 如果现在的那一列的索引小于yuansiHeadersLength(原始头部长度，右边部分),那么就代表删除的是原始的数据，不是新增的数据，否则删除的就是新增的数据
        if (index > 0) {
          // 当删除的是原始的数据时，需要查看这一列是否有修改记录，如果有修改记录就删掉
          for (let k in this.editRow) {
            // 需要把新增中的对应的删除
            if (this.editRow[k].updataOldCol && this.editRow[k].updataOldCol[index]) {
              delete this.editRow[k].updataOldCol[index]
            }
          }
          this.deleteColList.push((index).toString())
          this.yuansiHeadersLenght -= 1
          this.isUpdate = true
        } else {
          // 当删除的是新增的数据时
          var i = this.headerCopy.length + ind - this.yuansiHeadersLenght + 1
          this.addDataList = this.addDataList.filter(e => {
            return e != col.label
          })
          let keyOne = null
          for (let key in this.headerInfo) {
            if (this.headerInfo[key][0] == col.label) {
              keyOne = key
            }
          }
          delete this.headerInfo[keyOne]
          for (let k in this.editRow) {
            // 需要把新增中的对应的删除
            delete this.editRow[k].addNewCol[i]
            // 把新增的列中在他后面的要减1，比他小的加1
            let keyArr = Object.getOwnPropertyNames(this.editRow[k].addNewCol)
            for (let e of keyArr) {
              if (e > i) {
                let lsObj = this.editRow[k].addNewCol[e]
                this.editRow[k].addNewCol[e - 1] = lsObj
                lsObj.keyNumber = (e - 1).toString()
                delete this.editRow[k].addNewCol[e]
              }
            }
            // 从addDataList中删除
            // 将后面新增的列的索引应该减1
            // 需要把修改中对应的删除
          }
        }
        // 删除对应列
        this.getrowColor = []
        this.getcolumnColor = []
        this.$delete(this.headers, ind)
        this.isUpdate = true
				        }).catch(() => {
				          this.$message({
				            type: 'info',
				            message: '删除失败'
				          })
				        })
    },
    // 确定增加列的操作
    addCol () {
      // 判断该列是否是固定列，如果是不能新增
      const fixColumn = this.fixHeaders.findIndex(item => {
        return item == this.addValue
      })
      if (fixColumn !== -1) {
        this.$message({
          showClose: true,
          message: '特殊列无法通过该方式添加',
          type: 'warning'
        })
        this.addBtn = true
        this.addValue = ''
        this.widthHB = 50
        // this.$nextTick(()=>{
        //       this.$refs.table.doLayout()
        // })
        return
      }
      // 判断该列是否已经存在，存在就不能新增
      if (this.headers.findIndex(item => item.label == this.addValue) != -1) {
        this.$message({
          showClose: true,
          message: '无法添加重复列',
          type: 'warning'
        })
        this.addBtn = true
        this.addValue = ''
        this.widthHB = 50
        // this.$nextTick(()=>{
        //       this.$refs.table.doLayout()
        // })
        return
      }
      this.addValue = this.addValue.trim()
      if (this.addValue) {
        var fie = 'field' + (Number(this.yuansiMax) + Number(this.yuansiAdd))
        this.headers.push({
          prop: fie,
          label: this.addValue
        })
        if (this.headersCopy.findIndex(item => item == this.addValue) != -1) {
          // 这种情况下这一列就是先被删除了再添加回来的，这个时候就不往添加里面增加了，直接把删掉的记录删除
          const ind = this.deleteNameList.findIndex(a => a == this.addValue)
          this.deleteColList.splice(ind, 1)
          this.deleteNameList.splice(ind, 1)
        } else {
          this.addList.push(fie)
          this.addDataList.push(this.addValue)
          this.headerInfo[fie] = [this.addValue, this.specialCode_RevitDefaultParameters]
        }
        // 新增了几次，新增列的时候用来控制新增列的索引，主要是为了生成field后面的数字
        this.yuansiAdd += 1
        this.indexNum += 1
        // 用于还原没有保存的更改记录时对新增的数据进行操作
        // this.yuansiLength = this.yuansiHeaders.length-7 //重置原始长度，避免重复获取时一直减小,因为最开始有三列固定列不受影响，他们没有被算到长度中，所以要减去7
        this.getrowColor = []
        this.getcolumnColor = []
        this.isUpdate = true
      } else {
        this.$message({
          showClose: true,
          message: '无法添加空白列',
          type: 'warning'
        })
      }
      // 添加完之后对样式进行恢复
      this.addBtn = true
      this.addValue = ''
      this.widthHB = 50
      this.$nextTick(() => {
				      this.$refs.table.doLayout()
      })
    },
    blurMth () {
      this.addBtn = true
      this.addValue = ''
      this.widthHB = 50
    },
    addColBtn () {
      this.addBtn = false
      this.widthHB = 200
      this.$nextTick(() => {
				      this.$refs.table.doLayout()
      })
    },
    // 用于获取最近更改时变色，这个功能被舍弃了，暂时没有用到，先保留，后续需要这个功能时可在此基础上进行修改
    fixColorTable (cIndex, rIndex) {
      // 判断这一行、这一列是否存在
      let num = 6 - this.fixSum
      const a = this.getcolumnColor.indexOf(cIndex + num) != -1
      const b = this.getrowColor.indexOf(rIndex) != -1
      // 判断对应关系
      var arr = []
      for (var ind in this.getrowColor) {
        arr.push([this.getrowColor[ind], this.getcolumnColor[ind]])
      }
      let c = false
      for (var item of arr) {
        if (item[0] == rIndex && item[1] == cIndex - 0 + num) {
          c = true
        }
      }
      return a && b && c
    },
    // 修改燕娥所用，该功能已经丢弃，暂时没有用到
    changeColorTable (cIndex, rIndex) {
      // 判断这一行、这一列是否存在
      let num = 6 - this.fixSum
      const a = this.columnColor.indexOf(cIndex + num) != -1
      const b = this.rowColor.indexOf(rIndex) != -1
      // 判断对应关系
      var arr = []
      for (var ind in this.rowColor) {
        arr.push([this.rowColor[ind], this.columnColor[ind]])
      }
      let c = false
      for (var item of arr) {
        if (item[0] == rIndex && item[1] == cIndex - 0 + num) {
          c = true
        }
      }
      return a && b && c
    },
    // 行高亮时判断这一行是不是，暂时丢弃
    highRowMth (rIndex) {
      var flag = false
      if (this.highRows.indexOf(rIndex) != -1) {
        flag = true
      } else {
        flag = false
      }
      return flag
    },
    // table中每一列的排序
    sortColumn (col) {
      const key = col.prop
      this.tableData = hybirdSort(this.tableData, key, this.lastSH)
      this.lastSH = !this.lastSH
      this.handleCurrentChange(1)
      function compare (property) {
        return function (a, b) {
          var value1 = a[property]
          var value2 = b[property]
          return value1 - value2
        }
      }

      function compare2 (property) {
        return function (a, b) {
          var value1 = a[property]
          var value2 = b[property]
          return value2 - value1
        }
      }

      function hybirdSort (info, name, lastSH) {
        var rankArr = []
        // 数字0开头
        var zeroArr = info.filter((ele, index) => {
          if (Number(ele[name].substr(0, 1)) === 0) {
            return !isNaN(Number(ele[name].substr(0, 1)))
          }
        })
        rankArr = zeroArr
        // 数字非0开头
        var numArr = info.filter((ele, index) => {
          if (Number(ele[name].substr(0, 1)) !== 0) {
            return !isNaN(Number(ele[name].substr(0, 1)))
          }
        })
        // 汉字开头
        var reg = new RegExp('^[\u4e00-\u9fa5]')
        var wordArr = info.filter((ele, index) => {
          return reg.test(ele[name].substr(0, 1))
        })
        // 大写字母开头
        var regUpper = /^[A-Z]+$/
        var upperArr = info.filter((ele, index) => {
          return regUpper.test(ele[name].substr(0, 1))
        })
        // 剩余其他的开头
        var otherArr = info.filter((ele, index) => {
          return isNaN(Number(ele[name].substr(0, 1))) && !reg.test(ele[name].substr(0, 1)) && !regUpper.test(ele[name].substr(0, 1))
        })
        // 排序
        if (lastSH) {
          numArr = numArr.sort((a, b) => {
            return a[name].replace(/[^0-9]/ig, '') - b[name].replace(/[^0-9]/ig, '')
          })
          // 剩余其他的开头排序
          otherArr = otherArr.sort((a, b) => {
            return a[name].localeCompare(b[name])
          })
          // 大写字母开头排序
          upperArr = upperArr.sort((a, b) => {
            return a[name].localeCompare(b[name])
          })
          // 汉字开头排序
          wordArr = wordArr.sort((a, b) => {
            return a[name].localeCompare(b[name])
          })
          // 数字0开头排序
          zeroArr = zeroArr.sort((a, b) => {
            return a[name].replace(/[^0-9]/ig, '') - b[name].replace(/[^0-9]/ig, '')
          })
          info = wordArr
          info = info.concat(rankArr, numArr, upperArr, otherArr)
          // this.lastSH = !this.lastSH
          return info
        } else {
          numArr = numArr.sort((a, b) => {
            return b[name].replace(/[^0-9]/ig, '') - a[name].replace(/[^0-9]/ig, '')
          })
          // 剩余其他的开头排序
          otherArr = otherArr.sort((a, b) => {
            return b[name].localeCompare(a[name])
          })
          // 大写字母开头排序
          upperArr = upperArr.sort((a, b) => {
            return b[name].localeCompare(a[name])
          })
          // 汉字开头排序
          wordArr = wordArr.sort((a, b) => {
            return b[name].localeCompare(a[name])
          })
          // 数字0开头排序
          zeroArr = zeroArr.sort((a, b) => {
            return b[name].replace(/[^0-9]/ig, '') - a[name].replace(/[^0-9]/ig, '')
          })
          info = otherArr
          info = info.concat(upperArr, numArr, rankArr, wordArr)
          return info
        }
      }
    },
    // 邀请成员
    pullUserTable () {
      var _this = this
      let mappingTable = this.showViewId
      let createrUserId = this.createUserId
      let userTableList = JSON.parse(localStorage.getItem('userTableList'))
      // // 添加创始人
      this.$axios.get('/api/UserTable/GetUserList?MappingTable=' + mappingTable)
        .then(res => {
          res.forEach(element => {
            _this.userSource.push(element.userId)
            if (userTableList.findIndex(n => n.key == element.userId) > -1) {
              _this.userValue.push(element.userId)
            }
          })
        })
      let ind = userTableList.findIndex(i => {
        return i.key == createrUserId
      })
      userTableList.forEach(item => {
        item.disabled = false
      })
      userTableList[ind].disabled = true
      this.userTableList = userTableList
      this.userValue.push(createrUserId)
      this.$refs.addUser.dialogUserTable = true
    },
    // tableUserupdate(){
    // 	var _this = this
    // 	var addDto = {
    // 		MappingTable:'',
    // 		DeleteUserIds:[],
    // 		AddUserIds:[]
    // 	}
    // 	addDto.MappingTable = this.showViewId
    // 	this.userSource.forEach(element => {
    // 		if(_this.userValue.findIndex(n => n==element)==-1){
    // 			addDto.DeleteUserIds.push(element)
    // 		}
    // 	});
    // 	this.userValue.forEach(element => {
    // 		if(_this.userSource.findIndex(n => n==element)==-1){
    // 			addDto.AddUserIds.push(element)
    // 		}
    // 	});
    // 	this.$axios.post("/api/UserTable/AddUserTable",addDto)
    // 	.then(res => {
    // 		this.$message({
    // 			type:"success",
    // 			message:"操作成功"
    // 		})
    // 		this.dialogUserTable=false
    // 	})
    // },
    // 另存样表功能
    addTemplate () {
      let nowParList = []
      this.headers.forEach(item => {
        nowParList.push(item.label)
      })
      this.$axios.get(`/api/formhandle/gettableheaderoriginaldata?mappingTable=${this.tableGuid}`).then(res => {
        let newList = res.split(',')
        this.addTemplateList = nowParList.filter(item => {
          return !newList.find(item2 => item2 == item)
        })
        this.copyVisible = true
      })
    },
    // setColor({
    // 	row,
    // 	column,
    // 	rowIndex,
    // 	columnIndex
    // }) {
    // 	if(this.highRowMth(rowIndex)){
    // 		return "background:#ababab;"
    // 	}
    // 	if (columnIndex > this.yuansiLength -0  + this.fixSum ) {
    // 		return "background:#f6faff;"
    // 	}
    // 	// if(columnIndex == 0 || columnIndex == 1 || columnIndex == 2){
    // 	if(columnIndex <= this.fixSum){
    // 		return "background:#ececec;"
    // 	}
    // 	if(this.fixColorTable(columnIndex,rowIndex)){
    // 		return "background:#e6eff7;"
    // 	}
    // 	if(this.changeColorTable(columnIndex,rowIndex)){
    // 		return "background:#e6eff7;"
    // 	}
    // },
    // 渲染候补添加列的代码
    renderHeader (h) {
      return (<div>
        <el-button style="color:#fff;background:#8b8b8b;" icon="el-icon-plus" size="mini" v-show={this.addBtn} on-click={this.addColBtn} circle></el-button>
        <div v-show={!this.addBtn} style="display:flex">
          <el-input class="addCol-input" size="mini" ref="addInputNode" on-blur={this.addCol} v-model={this.addValue} placeholder="  s-例"></el-input><el-button class="addCol-input" style="width:32px;height:26px;" size="mini" icon="el-icon-check" circle on-click={this.addCol}></el-button>
        </div>
      </div>)
    }
  }
}
</script>
<style scoped lang="less">
	/deep/.el-dialog__header{
		text-align: left;
	}
	/deep/.el-input--mini .el-input__inner {
		height: 23px;
		line-height: 23px;
		padding: 0;
	}

	/deep/.el-button--mini,
	/deep/.el-button--mini.is-round {
		padding: 1px 8px;
	}

	/deep/.el-table--border:after,
	/deep/.el-table--group:after,
	/deep/.el-table:before {
		background-color: #e3e3e3;
	}

	/deep/.el-table--border,
	/deep/.el-table--group {
		border-color: #e3e3e3;
	}

	/deep/.el-table td,
	/deep/.el-table th.is-leaf {
		border-bottom: 1px solid #e3e3e3;
	}

	/deep/.el-table--border th,
	/deep/.el-table--border th.gutter:last-of-type {
		border-bottom: 1px solid #e3e3e3;
	}

	/deep/.el-table--border td,
	/deep/.el-table--border th {
		border-right: 1px solid #e3e3e3;
	}

	/deep/.el-icon-arrow-down:before {
		content: "\e790";
	}

	/* 对所有小图标进行了更改 */
	/deep/.el-button--mini.is-circle {
		margin-left: 5px;
		font-size: 16px;
		padding: 2px;
		border-color: #78c7e6;
		color: #159dd2;
		background-color: #ebf6fb;
		font-weight: bold;
	}
	.el-button--primary{
		background:#475065;
		border: none;
	}
	.header-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		/* background-color: #aaffff; */
	}

	.closeBtn {
		display: none;
		cursor: pointer;
		font-size: 16px;
		color: #ac0000;
		/* transition: .5s; */
	}

	.head-box:hover .closeBtn {
		display: inline;
		font-size: 16px;
		color: #ac0000;
	}
	.head-box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		// background: #000000;
		// height: 40px;
		// line-height: 40px;
	}
	.header-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 69px;
		margin-bottom: 10px;
	}

	.header-left {
		display: flex;
		align-items: center;
		font-weight: bold;
		// margin-left: 30px;

		/deep/.el-button--primary{
		background: #02a7f0;
		border: none;
		height: 40px;
		}

		.changeTable{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 266px;
			height: 40px;
			background: #FFFFFF;
			box-shadow: 0px 2px 4px 0px rgba(170, 170, 170, 0.5), 0px 2px 15px 0px rgba(241, 241, 241, 0.5);
			border-radius: 4px;
			box-sizing: border-box;
			padding: 0 30px;
			cursor: pointer;
			margin-right: 20px;
			span{
				font-weight: bold;
			}
		}
	}

	.header-right {
		display: flex;
		height: 40px;
		margin-right: 30px;
	}

	.table-box {
		padding: 15px 25px;
		// height: calc(100vh - 100px);
		height: 877px;
		box-sizing: border-box;
		background-color: #e7e7e7;
	}

	.table-container1 {
		// height: calc(100vh - 230px);
		height: 730px;
		overflow-y: auto;
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		background-color: #FFFFFF;
		padding: 20px;
		.pag-container{
			margin-top: 20px;
		}
	}

	.hdBtnBox .el-button--primary:nth-child(7){
		background: #02a7f0;
		border: none;
	}
	.hdBtnBox .el-button--primary:nth-child(8){
		background: #02a7f0;
		border: none;
	}
	.transfer-continaer{
		/deep/.el-dialog{
		  width: 1300px !important;
		  // height: 730px !important;
		}
		/deep/.el-dialog__title{
			font-size: 14px;
			font-weight: 600 !important;
		}
		/deep/.el-dialog__headerbtn{
			top: 10px;
		}
		/deep/.el-dialog__header{
			padding: 10px;
			background-color: #ffffff;
			// border-bottom: 1px solid #bebebf;
			// box-shadow: 0px 3px 5px 5px #000000;
		}
		/deep/.el-dialog__body{
			padding: 0;
			background-color: #f7f7f8;
		}

	}
	/deep/.addCol-input{
		display: flex;
		align-items: center;
	}

</style>
