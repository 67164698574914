<template>
	<div class="add-user-page">
		<el-dialog title="" :visible.sync="dialogUserTable" width="30%" :close-on-click-modal="false">
			<el-transfer height="500px" filterable
			:filter-method="filterMethod"
			filter-placeholder="请输入用户"
			:titles="isUser?['公司&外部 成员', '项目成员']:['项目成员', '表单成员']"
			v-model="userValue"
			:data="userTableList"
			style="text-align:left"
			>
				<div class="transfer-item" slot-scope="{option}">
					<span>{{option.label}}</span>
					<span>{{option.userName}}</span>
				</div>
			</el-transfer>
			<p class="bottomBtn">
				<el-button type="danger" @click="cancle">取消</el-button>
				<el-button type="primary" class="search" @click="tableUserupdate">确认</el-button>
			</p>
		</el-dialog>
	</div>
</template>

<script>
export default {
  props: ['mappingtable', 'userTableList', 'userSource', 'userValueC', 'isUser'],
  data () {
    return {
      dialogUserTable: false,
      filterMethod (query, item) {
				  return item.label.indexOf(query) > -1
      },
      userValue: ''
    }
  },
  mounted () {
    this.userValue = this.userValueC
    console.log(this.userValue)
    console.log(this.userTableList)
    console.log(this.userSource)
  },
  methods: {
    tableUserupdate () {
      if (this.isUser) {
        let yuansiList = []
        let addList = this.userValue.filter(item => {
          return !this.userSource.find(item2 => item2 == item)
        })
        let leftList = this.userTableList.filter(item => {
          return !this.userValue.find(item2 => item.key == item2)
        })
        let deleteList = this.userSource.filter(item => {
          return leftList.find(item2 => item2.key == item)
        })
        let data = {
          projectId: localStorage.getItem('eleProjectId') - 0,
          userIdList: addList,
          deleteUserIdList: deleteList
        }
        console.log(data)
        this.$axios.post('/api/project/pullprojectuserlist', data).then(res => {
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.$parent.init()
          this.dialogUserTable = false
        })
      } else {
        var _this = this
        var addDto = {
          MappingTable: '',
          DeleteUserIds: [],
          AddUserIds: []
        }
        addDto.MappingTable = this.mappingtable
        this.userSource.forEach(element => {
          if (_this.userValue.findIndex(n => n == element) == -1) {
            addDto.DeleteUserIds.push(element)
          }
        })
        this.userValue.forEach(element => {
          if (_this.userSource.findIndex(n => n == element) == -1) {
            addDto.AddUserIds.push(element)
          }
        })
        this.$axios.post('/api/UserTable/AddUserTable', addDto)
          .then(res => {
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.dialogUserTable = false
          })
      }
    },
    cancle () {
      this.dialogUserTable = false
    }
  }
}
</script>

<style scoped lang="less">
	.add-user-page{
		.transfer-item{
			width: 374px;
			display: flex;
			>span{
				display: inline-block;
				text-align: left;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			>span:nth-child(1){
				width: 160px;
			}
			>span:nth-child(2){
				width: 214px;
				box-sizing: border-box;
			}
		}
		/deep/.el-dialog{
			width: 1070px !important;
		}
		/deep/.el-transfer-panel{
		width: 415px;
		height: 500px;
		}
		/deep/.el-transfer-panel__list.is-filterable{
		height: 400px;
		}
		/deep/.bottomBtn {
			text-align: right;
			margin-top: 15px;
			.search {
				background-color: #475065;
				border: none;
				margin-left: 5px;
			}
		}
		/deep/.el-transfer__button {
			background-color: #475065;
			border: 1px solid #475065;
		}
	}
</style>
