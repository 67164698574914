import {
  mapState
} from 'vuex'
export const share = {
  data () {
    return {
      toProjectList: [],
      toFloderList: [],
      toSpaceList: [],
      ruleFormCopy: {
        name: '',
        spaceId: '',
        projectType: '',
        projectId: '',
        floderId: ''
      },
      rulesCopy: {
        name: {
          required: true,
          message: '请填写样表名称',
          trigger: 'blur'
        },
        spaceId: {
          required: true,
          message: '请选择企业',
          trigger: 'change'
        },
        projectType: {
          required: true,
          message: '请选择项目分类',
          trigger: 'change'
        },
        projectId: {
          required: true,
          message: '请选择项目',
          trigger: 'change'
        },
        floderId: {
          required: true,
          message: '请选择文件夹',
          trigger: 'change'
        }
      },
      needCopyItem: {},
      copyVisible: false,
      radio: 0,
      toSpaceIdOrProjectId: 0,
      spaceFloderData: [],
      isToSelecet: {
        common: true,
        space: true,
        project: true
      },
      isDisabled: false
      // needCopyStandard:{}
    }
  },
  mounted () {
    // this.getSpaceList()
    this.initSharData()
  },
  computed: {
    ...mapState('spacePermiss', {
      spacePermiss: 'spacePermiss'
    })
  },
  methods: {
    // 重置选择状态
    initToSelect () {
      this.isToSelecet = {
        common: true,
        space: true,
        project: true
      }
    },
    // 初始状态肯定是公共的，直接获取一下公共的数据
    initSharData () {
      // 判断当前用户身份，判断当前所处层级
      if (this.isTablePage) {
        this.radio = 2
        this.initToSelect()
        this.isToSelecet.common = false
        this.isToSelecet.space = false
        this.getSpaceList()
      } else {
        if (this.tier == 0) {
          // this.getFloderSpaceData(0)
          this.radio = 1
          this.initToSelect()
          this.isToSelecet.common = false
          this.getSpaceList()
        } else if (this.tier == 1) {
          if (this.spacePermiss.isSmartAdmin) {
            this.radio = 0
            this.initToSelect()
            this.isToSelecet.space = false
            this.getFloderSpaceData(0)
          } else {
            this.radio = 2
            this.initToSelect()
            this.isToSelecet.space = false
            this.isToSelecet.common = false
          }
        } else {
          if (this.spacePermiss.isSmartAdmin) {
            this.radio = 0
            this.initToSelect()
            this.getFloderSpaceData(0)
          } else if (this.spacePermiss.isAdmin || this.spacePermiss.isSpaceOwner) {
            this.radio = 1
            this.initToSelect()
            this.isToSelecet.common = false
            this.getSpaceList()
          } else {
            this.radio = 2
            this.initToSelect()
            this.isToSelecet.common = false
            this.isToSelecet.space = false
            this.getSpaceList()
          }
          // this.radio = 0
        }
      }
    },
    // 判断当前层级然后获取相关数据

    // 单选按钮切换事件
    changeTarget (val) {
      // 如果选择的是企业
      this.toProjectList = []
      this.toFloderList = []
      this.toSpaceList = []
      if (val == 1) {
        this.getSpaceList()
      } else if (val == 2) {
        // 重置一下选择的企业
        this.getSpaceList()
        this.ruleFormCopy.spaceId = ''
        this.ruleFormCopy.floderId = ''
      } else {
        this.getFloderSpaceData(0)
        this.ruleFormCopy.floderId = ''
      }
    },
    getSpaceList () {
      this.ruleFormCopy.sapceId = ''
      this.$axios.get('/api/Space/GetSpaceAndProjectType').then(res => {
        this.toSpaceList = res
        if (this.tier == 1 || this.tier == 2 || this.isTablePage) {
          this.toSpaceList = this.toSpaceList.filter(item => {
            return item.id == JSON.parse(localStorage.getItem('currentSpace')).id
          })
          this.ruleFormCopy.spaceId = this.toSpaceList[0].id
          this.isDisabled = true
          console.log(this.radio)
          // if(radio == 1)
          // if(this.radio == 1){
          if (this.tier == 2 && this.radio == 1) {
            this.getFloderData(this.radio, this.toSpaceList[0].id)
          } else {
            this.getProjectData(this.toSpaceList[0].id)
          }

          // }else{
          // }
        }
      })
    },
    getProjectData (id) {
      this.$axios.get(`/api/project/getprojectbyspaceid?spaceId=${id}`).then(res => {
        for (let item of res) {
          this.toProjectList.push(...item.projectListDto)
        }
        // this.toProjectList = res
      })
      this.ruleFormCopy.projectId = ''
    },
    // 获取当前空间的以及当前项目的样表，不需要传值
    getFloderSpaceData (type) {
      this.$axios.get(`/api/standard/getstandardlist?Type=${type}`).then(res => {
        this.toFloderList = res
      })
    },
    getFloderData (type, id) {
      this.toFloderList = []
      this.$axios.get(`/api/standard/getstandardlist2?Type=${type}&SpaceIdOrProjectId=${id}`).then(res => {
        this.toFloderList = res
      })
      this.ruleFormCopy.floderId = ''
      // ruleFormCopy: {
      // 	name: "",
      // 	spaceId: "",
      // 	projectType: "",
      // 	projectId: "",
      // 	floderId:''
      // },
    },
    toSpceChange (val) {
      // 如果是分享到企业就应该立马获取企业中的文件夹
      let type = 1
      if (this.radio == 1) {
        this.getFloderData(type, val)
      } else {
        // 如果是分享到项目就立马获取项目
        this.getProjectData(val)
      }
    },
    toProjectChange (val) {
      let type = 2
      this.getFloderData(type, val)
    },
    copyToTarget (ref) {
      let standardId = this.needCopyItem.id
      let type = Number(this.radio)
      let spaceIdOrProjectId = this.ruleFormCopy.projectId || this.ruleFormCopy.spaceId
      let standardName = this.ruleFormCopy.name
      let parentId = this.ruleFormCopy.floderId

      this.$refs[ref].validate(valid => {
        if (valid) {
          this.$axios.get(
            `/api/Standard/CopyStandard?StandardId=${standardId}&Type=${type}&SpaceIdOrProjectId=${spaceIdOrProjectId}&StandardName=${standardName}&ParentId=${parentId}`
          ).then(res => {
            this.$message({
              type: 'success',
              message: '分享成功'
            })
            this.copyVisible = false
          })
        }
      })
    },
    addToTarget (ref) {
      this.$refs[ref].validate(valid => {
        // return
        if (valid) {
          let data = {
            'Name': this.ruleFormCopy.name,
            'Type': 2,
            'SpaceIdOrProjectId': this.ruleFormCopy.projectId || this.ruleFormCopy.spaceId,
            'ParentId': this.ruleFormCopy.floderId,
            'Parameters': [{
              'Name': '默认分类',
              'ParameterInfoNames': this.addTemplateList
            }]
          }
          this.$axios.post('/api/standard/addstandard', data).then(res => {
            console.log(res)
            this.$message({
              type: 'success',
              message: '保存成功'
            })
            this.copyVisible = false
          })
        }
      })
    },
    async shareTo (item) {
      await this.initSharData()
      this.ruleFormCopy.name = item.name
      this.needCopyItem = item
      this.copyVisible = true
    }
  }
}
