export const deriveExcel = {
  data () {
    return {}
  },
  methods: {
    deriveExcel (api, data, suffix) {
      this.$axios.post(api, data, { responseType: 'arraybuffer' }).then(res => {
        let type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        const href = URL.createObjectURL(new Blob([res], { type }))
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = href // 指定下载链接
        a.download = this.projectname + '_' + this.tablename + (suffix || '')// 指定下载文件名
        a.click() // 触发下载
        URL.revokeObjectURL(a.href) // 释放URL对象
        this.$message({
          showClose: true,
          message: '下载完成',
          type: 'success'
        })
      })
    }
  }
}
