<template>
  <!-- <div>复杂穿梭框</div> -->
  <div class="tree-transfer-container">
    <div class="transfer-left">
      <div class="left-one">
        <div class="header-box">
          <el-select @change="selectChange" size="mini" v-model="selectValue" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="main-box">
          <!-- <ul> -->
          <el-collapse v-model="activeNames">
            <el-collapse-item
              v-for="(floderItem,index1) of datas"
              :id="floderItem.id"
              :key="floderItem.id"
            >
              <template slot="title">
                <p class="title-scope">
                  <span class="el-icon-folder"></span>
                  <span>{{floderItem.name}}</span>
                </p>
              </template>
              <ul>
                <li
                  :class="activeId==item.id ? 'active' : 'actives'"
                  @click="pitchTemplateLibrary(item,index,index1)"
                  v-for="(item,index) of floderItem.standList"
                  :key="item.id"
                >
                  <span class="iconfont icon-biaozhun" style="margin-right: 15px;"></span>
                  {{item.name}}
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>

          <!-- </ul> -->
        </div>
      </div>
      <div class="left-two">
        <div class="header-box">参数分类列表</div>
        <div class="main-box">
          <ul>
            <li
              v-for="(item,index) of parameterList"
              :class="activeIndexTwo==index ? 'active' : ''"
              :key="index"
              @click="pitchType(item,index)"
            >{{item.name}}</li>
          </ul>
        </div>
      </div>
      <div class="left-three">
        <div class="header-box">参数列表</div>
        <div class="main-box">
          <ul>
            <li
              v-for="(item,index) of parameterInfoList"
              :class="activeIndexThree==index ? 'active' : ''"
              :key="item.id"
              @click="pitchInfo(item,index)"
            >{{item.name}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="transfer-middle">
      <div class="btn-container">
        <span class="el-icon-d-arrow-right" @click="add"></span>
        <span class="el-icon-d-arrow-left" @click="remove"></span>
      </div>
    </div>
    <div class="transfer-right">
      <div class="right-one">
        <div class="header-box">
          <p>预套用参数分类列表</p>
          <p>
            <span @click="addType" class="el-icon-circle-plus"></span>
          </p>
        </div>
        <div class="main-box">
          <ul>
            <!-- <li :class="activeIndexFour==-2 ? 'active' : ''" @click="lookAll">全部参数</li> -->
            <li
              v-for="(item,index) of unlimberTypeList"
              :class="activeIndexFour==index ? 'active' : ''"
              class="unlimberBox"
              :key="index"
              @click="pitchUnlimberType(item,index)"
            >{{item.name}}</li>
            <li v-show="isAddType" class="addBox">
              <el-input
                ref="typeInput"
                @keyup.enter.native="$event.target.blur"
                @blur="sureAddType"
                size="mini"
                v-model="addTypeName"
                placeholder="请输入分类名称"
              ></el-input>
              <el-button type="primary" size="mini" @click="sureAddType">确定</el-button>
            </li>
          </ul>
          <div>
            <span @click="typeMoveUp">上移</span>
            <span @click="typeMoveDown">下移</span>
          </div>
        </div>
      </div>
      <div class="right-two">
        <div class="header-box">
          <p>预套用参数</p>
          <p>
            <span class="el-icon-info" style="margin-right: 10px;" @click="lookAll"></span>
            <span @click="addInfo" class="el-icon-circle-plus"></span>
          </p>
        </div>
        <div class="main-box">
          <ul>
            <li
              v-for="(item,index) of unlimberParList"
              style="white-space: nowrap;"
              :class="activeIndexFive==index ? 'active' : ''"
              :key="item.id"
              @click="pitchParList(item,index)"
            >{{item.name}}</li>
            <li v-show="isAddInfo" class="addBox">
              <el-input
                ref="parInput"
                @keyup.enter.native="$event.target.blur"
                @blur="sureAddInfo"
                size="mini"
                v-model="addInfoName"
                placeholder="请输入分类名称"
              ></el-input>
              <el-button type="primary" size="mini" @click="sureAddInfo">确定</el-button>
            </li>
          </ul>
          <div>
            <span @click="sort(unlimberParList,2,positive2)">排序</span>
            <span @click="parMoveUp">上移</span>
            <span @click="parMoveDown">下移</span>
            <span class="sure" @click="sure">确认</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'codes'],
  data () {
    return {
      positive2: false,
      yuansiDatas: [],
      datas: [],
      activeIndexOne: -1,
      parameterList: [],
      yuansiParType: { },
      activeIndexTwo: -1,
      parameterInfoList: [],
      activeIndexThree: -1,
      activeList: [],
      activeType: 1, // 选中的类型，第一层，第二层，第三层
      unlimberTypeList: [],
      unlimberParList: [],
      activeIndexFour: -1,
      activeItem: {},
      addPar: false,
      activeIndexFive: -1,
      removeItem: {},
      isAddType: false,
      addTypeName: '',
      chushiId: -10,
      isAddInfo: false,
      addInfoName: '',
      fixCode: {},
      activeNames: ['1'],
      activeId: -1,
      floderIndex: -1,
      options: [
        {
          value: 0,
          label: '公共数据规格'
        },
        {
          value: 1,
          label: '公司数据规格'
        },
        {
          value: 2,
          label: '项目数据规格'
        }
      ],
      selectValue: 2
    }
  },
  mounted () {
    this.datas = this.data
    this.yuansiDatas = JSON.parse(JSON.stringify(this.data))
    console.log(this.datas)
    console.log(this.yuansiDatas)
  },
  watch: {
    fixCode: function (newVal, oldVal) {
      this.fixCode = this.codes
      // this.drawChart();
      // this.init();
    }
  },
  methods: {
    // 排序
    sort (arr, statu, positive) {
      if (positive) {
        arr.sort((a, b) => (a['name'] > b['name'] ? 1 : -1))
      } else {
        arr.sort((a, b) => (a['name'] < b['name'] ? 1 : -1))
      }
      if (statu == 1) {
        this.positive1 = !positive
      } else {
        this.positive2 = !positive
      }
    },
    init () {
      // 初始化固定列的数据，该数据是父组件传过来的
      setTimeout(() => {
        this.parameterList[0].parameterInfoList[0].name = this.fixCode.spaceCode
        this.parameterList[0].parameterInfoList[1].name = this.fixCode.projectCode
        this.parameterList[0].parameterInfoList[2].name = this.fixCode.monomer
        this.parameterList[0].parameterInfoList[3].name = this.fixCode.floor
        this.parameterList[0].parameterInfoList[4].name = this.fixCode.componentCode
        this.parameterList[0].parameterInfoList[5].name = this.fixCode.serialNumber
        this.yuansiParType = JSON.parse(JSON.stringify(this.parameterList))
      }, 1)
    },
    pitchTemplateLibrary (item, index, index1) {
      console.log(item)
      console.log(index1)
      this.activeId = item.id
      this.floderIndex = index1
      // this.activeIndexOne = index
      this.activeIndexTwo = -1
      this.activeIndexThree = -1
      console.log(this.parameterList)
      if (this.addPar) {
        this.parameterList = item.parameterList
      } else {
        this.parameterList.splice(1)
        this.parameterList.push(...item.parameterList)
      }
      this.parameterInfoList = []
      this.activeList = this.parameterList
      this.activeItem = item
      this.activeType = 1
    },
    pitchType (item, index) {
      this.activeIndexTwo = index
      let obj = {}
      this.parameterInfoList = item.parameterInfoList.reduce((items, next) => {
        obj[next.id] ? '' : (obj[next.id] = true && items.push(next))
        return items
      }, [])
      this.activeList = item
      this.activeIndexThree = -1
      this.activeType = 2
    },
    pitchInfo (item, index) {
      this.activeIndexThree = index
      this.activeList = item
      this.activeIndexTwo = this.parameterList.findIndex(item2 => {
        return this.activeList.parameterId == item2.id
      })
      this.activeType = 3
    },
    // 往右添加

    add () {
      console.log(this.activeIndexTwo)
      if (this.activeType == 1) {
        this.unlimberTypeList.push(...this.activeList)
        this.datas.forEach(item => {
          item.standList = item.standList.filter(item => {
            return item.id != this.activeItem.id
          })
        })
        let copyData = this.datas[this.floderIndex]
        copyData.standList = copyData.standList.filter(item => {
          return item.id != this.activeItem.id
        })
        this.$set(this.datas, this.floderIndex, copyData)
        this.activeIndexOne = -1
        this.activeIndexTwo = -1
        this.activeIndexThree = -1
        this.parameterList = []
        this.addPar = true
      } else if (this.activeType == 2) {
        // 1.添加到右边
        // 看看右边是不是已经有了，有了的话应该是给右边的去追加子属性
        let findIndex = this.unlimberTypeList.findIndex(item => {
          return item.id == this.activeList.id
        })

        if (findIndex >= 0) {
          this.unlimberTypeList[findIndex].parameterInfoList.push(
            ...this.activeList.parameterInfoList
          )
        } else {
          this.unlimberTypeList.push(this.activeList)
        }
        // 2.判断是不是编码参数，如果是就直接删掉
        if (this.activeList.id == -1) {
          this.parameterList = this.parameterList.splice(1)
          this.addPar = true
        } else {
          let index = this.datas.findIndex(item => {
            return item.id == this.activeList.standardId
          })
          let findIndex = this.parameterList.findIndex(item => {
            return item.id == this.activeList.id
          })
          console.log(findIndex)
          this.parameterList.splice(findIndex, 1)
        }
        this.parameterInfoList = []
        this.activeIndexTwo = -1
        // 3.走对应的数据规格库中删掉
      } else {
        console.log(this.activeList)
        let find
        let index
        // 判断是不是编码参数中的值
        if (this.activeList.id <= 0) {
          let addList = []
          if (this.activeList.id == -1) {
            // 选择的是企业编码
            addList = this.parameterInfoList.filter(item => {
              return item.id == -1
            })
            // 项目编码
          } else if (this.activeList.id == -2) {
            addList = this.parameterInfoList.filter(item => {
              return item.id == -2
            })
          } else if (this.activeList.id == -3) {
            // 选择的是单体
            addList = this.parameterInfoList.filter(item => {
              return item.id == -3
            })
            // 楼层
          } else if (this.activeList.id == -4) {
            addList = this.parameterInfoList.filter(item => {
              return item.id == -4
            })
            // 构建编码
          } else if (this.activeList.id == -5) {
            addList = this.parameterInfoList.filter(item => {
              return item.id == -5
            })
          } else if (this.activeList.id == -6) {
            // 流水号
            addList = this.parameterInfoList.filter(item => {
              return item.id == -5 || item.id == -6
            })
          }
          // 得到了需要操作的数据之后就开始操作数据
          find = this.parameterList.find(item => {
            return item.id == this.activeList.parameterId
          })
          find = JSON.parse(JSON.stringify(find))
          // 判断右边是不是已经有了
          index = this.unlimberTypeList.findIndex(item => {
            return item.id == -1
          })
          console.log(index)
          if (index >= 0) {
            this.unlimberTypeList[index].parameterInfoList.push(...addList)
          } else {
            find.parameterInfoList = addList
            this.unlimberTypeList.push(find)
          }
          // 操作完成之后删除左边
          // 至少删掉两个
          for (let item of addList) {
            let ind = this.parameterInfoList.findIndex(item2 => {
              return item.id == item2.id
            })
            this.parameterInfoList.splice(ind, 1)
          }
          if (
            this.parameterList[this.activeIndexTwo].parameterInfoList.length ==
            0
          ) {
            this.parameterList = this.parameterList.splice(1)
            this.addPar = true
          }
          this.activeIndexThree = -1
        } else {
          find = this.parameterList.find(item => {
            return item.id == this.activeList.parameterId
          })
          // 判断右边是不是已经有了
          if (find != undefined) {
            find = JSON.parse(JSON.stringify(find))
            index = this.unlimberTypeList.findIndex(item => {
              return item.id == find.id
            })
          }
          if (index != undefined) {
            if (index >= 0) {
              this.unlimberTypeList[index].parameterInfoList.push(
                this.activeList
              )
            } else {
              find.parameterInfoList = [this.activeList]
              this.unlimberTypeList.push(find)
            }
            // 添加完成之后就需要删掉左边的
            this.parameterInfoList.splice(this.activeIndexThree, 1)
            // this.datas[this.activeIndexOne].parameterList[this.activeIndexTwo] = this.parameterInfoList
            this.activeIndexThree = -1
          }
        }
      }
      for (let i = 0; i < this.parameterList.length; i++) {
        if (this.parameterList[i].id == this.activeList.parameterId) {
          for (
            let j = 0;
            j < this.parameterList[i].parameterInfoList.length;
            j++
          ) {
            if (
              this.parameterList[i].parameterInfoList[j].id ==
              this.activeList.id
            ) {
              this.parameterList[i].parameterInfoList.splice(j, 1)
              break
            }
          }
          console.log(this.parameterList[i])
          if (!this.parameterList[i].parameterInfoList.length) {
            this.parameterList.splice(i, 1)
          }
          break
        }
      }
      let obj = {}
      this.unlimberParList = this.unlimberParList.filter(item => {
        return Object.keys(item).length != 0
      })
      this.unlimberParList = this.unlimberParList.reduce((items, next) => {
        obj[next.id] ? '' : (obj[next.id] = true && items.push(next))
        return items
      }, [])
      this.activeList = []
      this.lookAll()
    },
    remove () {
      // 判断是不是编码成参数相关的
      // console.log(this.removeItem)
      console.log(this.unlimberTypeList)
      if (JSON.stringify(this.removeItem) == '{}') {
        this.$message({
          message: '请先选择需要移除的选项',
          type: 'warning'
        })
        return
      }
      const minusnoes = JSON.parse(JSON.stringify(this.yuansiParType))
      if (this.removeItem.id <= -1) {
        // console.log(rem)
        // 1.从右侧删除出
        if (this.activeIndexFive == -1) {
          for (let i = 0; i < this.parameterList.length; i++) {
            if (this.parameterList[i].id <= -1) {
              this.parameterList.splice(i, 1)
            }
          }
          const parameterInfosst = JSON.parse(
            JSON.stringify(this.parameterList)
          )
          // 当选择的是分类中的编码参数的时候
          this.unlimberTypeList.splice(this.activeIndexFour, 1)
          this.unlimberParList = []
          // this.activeIndexFour = -1
          this.addPar = false
          // let arr = JSON.parse(JSON.stringify(this.parameterList))
          this.parameterList = []
          this.parameterInfoList = []
          // this.parameterList.push(this.yuansiParType)
          this.parameterList.push(minusnoes)
          this.parameterList.push(...parameterInfosst)
          this.parameterInfoList = this.parameterList[0].parameterInfoList
          // this.parameterList.push(...arr)
          this.activeIndexFour = -1
          // this.unlimberParList = []
        } else {
          let arr = []
          // 选择的是编码参数中的子项的时候
          if (this.removeItem.id == -1) {
            // 为企业编码和项目编码的时候
            arr = this.unlimberParList.filter(item => {
              return item.id == -1
            })
            this.unlimberParList = this.unlimberParList.filter(item => {
              return item.id != -1
            })
            if (this.parameterInfoList.length) {
              if (this.parameterInfoList[0].parameterId == -1) {
                this.parameterInfoList.splice(0, 0, this.removeItem)
              }
            }
            //
            console.log(this.unlimberParList)
          } else if (this.removeItem.id == -2) {
            arr = this.unlimberParList.filter(item => {
              return item.id == -2
            })
            this.unlimberParList = this.unlimberParList.filter(item => {
              return item.id != -2
            })
            if (this.parameterInfoList.length) {
              if (this.parameterInfoList[0].parameterId == -1) {
                this.parameterInfoList.splice(1, 0, this.removeItem)
              }
            }
            //   this.parameterInfoList.splice(1,0,this.removeItem)
          } else if (this.removeItem.id == -3) {
            // 为单体和楼层的时候
            arr = this.unlimberParList.filter(item => {
              return item.id == -3
            })
            this.unlimberParList = this.unlimberParList.filter(item => {
              return item.id != -3
            })
            if (this.parameterInfoList.length) {
              if (this.parameterInfoList[0].parameterId == -1) {
                this.parameterInfoList.splice(2, 0, this.removeItem)
              }
            }
            // this.parameterInfoList.splice(2,0,this.removeItem)
          } else if (this.removeItem.id == -4) {
            arr = this.unlimberParList.filter(item => {
              return item.id == -4
            })
            this.unlimberParList = this.unlimberParList.filter(item => {
              return item.id != -4
            })
            if (this.parameterInfoList.length) {
              if (this.parameterInfoList[0].parameterId == -1) {
                this.parameterInfoList.splice(3, 0, this.removeItem)
              }
            }
            // this.parameterInfoList.splice(3,0,this.removeItem)
          } else if (this.removeItem.id == -5) {
            arr = this.unlimberParList.filter(item => {
              return item.id == -5 || item.id == -6
            })
            this.unlimberParList = this.unlimberParList.filter(item => {
              return item.id != -5 && item.id != -6
            })
            if (this.parameterInfoList.length) {
              if (this.parameterInfoList[0].parameterId == -1) {
                this.parameterInfoList.splice(4, 0, this.removeItem)
              }
            }
            // this.parameterInfoList.splice(4,0,this.removeItem)
          } else if (this.removeItem.id == -6) {
            // 为构建编码和流水号的时候
            arr = this.unlimberParList.filter(item => {
              return item.id == -6
            })
            this.unlimberParList = this.unlimberParList.filter(item => {
              return item.id != -6
            })
            if (this.parameterInfoList.length) {
              if (this.parameterInfoList[0].parameterId == -1) {
                this.parameterInfoList.splice(5, 0, this.removeItem)
              }
            }

            // this.parameterInfoList.splice(5,0,this.removeItem)
          }
          let nowTypeList = {}
          // 当前处于全部参数的时候
          if (this.activeIndexFour == -2) {
            if (this.removeItem.id == -5) {
              nowTypeList = this.unlimberTypeList.find(item => {
                return item.id == this.removeItem.parameterId || item.id == -6
              })
              nowTypeList.parameterInfoList = nowTypeList.parameterInfoList.filter(
                item => {
                  return item.id != this.removeItem.id && item.id != -6
                }
              )
            } else {
              nowTypeList = this.unlimberTypeList.find(item => {
                return item.id == this.removeItem.parameterId
              })
              nowTypeList.parameterInfoList = nowTypeList.parameterInfoList.filter(
                item => {
                  return item.id != this.removeItem.id
                }
              )
              this.unlimberTypeList = this.unlimberTypeList.filter(item => {
                return item.parameterInfoList.length != 0
              })
              console.log(nowTypeList)
              console.log(this.unlimberTypeList)
            }
            // 如果不是全部参数的时候
          } else {
            for (let i = 0; i < this.unlimberTypeList.length; i++) {
              if ((this.unlimberTypeList[i].id = this.removeItem.parameterId)) {
                for (
                  let j = 0;
                  j < this.unlimberTypeList[i].parameterInfoList.length;
                  j++
                ) {
                  if (
                    this.unlimberTypeList[i].parameterInfoList[j].id ==
                    this.removeItem.id
                  ) {
                    this.unlimberTypeList[i].parameterInfoList[j].splice(j, 1)
                    break
                  }
                }

                break
              }
            }
            nowTypeList = this.unlimberParList
          }
          // 如果左侧的编码参数已经被清空了的话
          if (this.addPar) {
            this.addPar = false
            let cloneList = JSON.parse(JSON.stringify(this.parameterList))
            this.parameterList = []
            let cloneYuansiList = JSON.parse(
              JSON.stringify(this.yuansiParType)
            )
            cloneYuansiList.parameterInfoList = arr
            this.parameterList.push(cloneYuansiList)
            this.parameterList.push(...cloneList)
          } else {
            // 如果编码参数没有被清空
            let cloneList = JSON.parse(JSON.stringify(this.parameterList[0]))
            cloneList.parameterInfoList.push(...arr)
            this.$set(this.parameterList, 0, cloneList)
            console.log(this.parameterList)
          }
          this.activeIndexFive = -1
        }
      } else {
        // 不是编码参数的时候，这个时候就要添加到左边去，但是会出现左边的父级已经全部被清空的情况，根本没法添加，所以只能过滤初始数据
        // if(this.activeIndexFive == -1){
        // 选择的是参数分类列表的时候
        // if (this.activeIndexFour == -2) {
        // 	// 此时选择的是全部参数，选择全部参数的时候全部复原
        // 	this.datas = JSON.parse(JSON.stringify(this.yuansiDatas))
        // 	this.unlimberParList = []
        // 	this.unlimberTypeList = []
        // 	return
        // } else
        // 如果选中的是预套用参数分类列表
        if (this.activeIndexFive == -1) {
          // 右边数据删除
          this.unlimberTypeList.splice(this.activeIndexFour, 1)
          this.unlimberParList = []
          this.activeIndexFour = -1
          this.activeIndexFive = -1
          // 如果选中的是预套用参数
        } else {
          // 当前处于全部参数的时候
          this.unlimberParList.splice(this.activeIndexFive, 1)
          let nowTypeList = {}
          if (this.activeIndexFour == -2) {
            // nowTypeList = this.unlimberTypeList.find(item => {
            // 	return item.id == this.removeItem.parameterId
            // })
            for (let i = 0; i < this.unlimberTypeList.length; i++) {
              if (this.unlimberTypeList[i].id == this.removeItem.parameterId) {
                nowTypeList = this.unlimberTypeList[i]
                break
              }
            }
            nowTypeList.parameterInfoList = nowTypeList.parameterInfoList.filter(
              item => {
                return item.id != this.removeItem.id
              }
            )
            // 如果不是全部参数的时候
          } else {
            nowTypeList = this.unlimberParList
          }
          if (this.unlimberParList.length == 0) {
            this.unlimberTypeList.splice(this.activeIndexFour, 1)
            this.unlimberParList = []
            this.activeIndexFour = -1
            this.activeIndexFive = -1
          }
          // const index = this.unlimberTypeList.findIndex(item => {
          // 	return item.parameterInfoList.find(item2 => item2.id == this.removeItem.id)
          // })
          // console.log(this.unlimberTypeList)
          // console.log(this.removeItem.id)
          // console.log(index)
          // this.unlimberTypeList[index].parameterInfoList = nowTypeList.parameterInfoList
          // this.activeIndexFive = -1
        }
        // 左边数据处理

        let cloneYuanSi = JSON.parse(JSON.stringify(this.yuansiDatas))
        // 对左边的数据进行一次筛选，凡是右边有的左边就不要
        let allUnlimberParList = []
        for (let item of this.unlimberTypeList) {
          allUnlimberParList.push(...item.parameterInfoList)
        }
        for (let index1 in cloneYuanSi) {
          for (let index2 in cloneYuanSi[index1].standList) {
            for (let index3 in cloneYuanSi[index1].standList[index2]
              .parameterList) {
              let obj =
                cloneYuanSi[index1].standList[index2].parameterList[index3]
                  .parameterInfoList
              obj = obj.filter(item => {
                return !allUnlimberParList.find(item2 => {
                  return item2.id == item.id
                })
              })
              cloneYuanSi[index1].standList[index2].parameterList[
                index3
              ].parameterInfoList = obj
            }
            cloneYuanSi[index1].standList[index2].parameterList = cloneYuanSi[
              index1
            ].standList[index2].parameterList.filter(item => {
              return item.parameterInfoList.length != 0
            })
          }
        }
        this.datas = cloneYuanSi
        const inx = this.datas[this.floderIndex].standList.findIndex(item => {
          return item.id == this.activeId
        })
        let obj = {}
        this.parameterList = this.parameterList.reduce((items, next) => {
          obj[next.id] ? '' : (obj[next.id] = true && items.push(next))
          return items
        }, [])
        const datas = JSON.parse(
          JSON.stringify(
            this.datas[this.floderIndex].standList[inx].parameterList
          )
        )
        const parameter = JSON.parse(JSON.stringify(this.parameterList))
        let parameters
        for (let m = 0; m < parameter.length; m++) {
          if (parameter[m].id == -1) {
            this.parameterList = []
            this.parameterList.push(parameter[m])
            parameter.splice(m, 1)
          }
        }
        if (parameter.length) {
          for (let j = 0; j < datas.length; j++) {
            for (let i = 0; i < parameter.length; i++) {
              if (parameter[i].id == datas[j].id) {
                parameter[i] = datas[j]
                break
              }
              if (i == parameter.length - 1) {
                parameters = datas[j]
              }
            }
          }
          this.parameterList.push(...parameter)
          if (parameters != undefined) {
            this.parameterList.push(parameters)
          }
        } else {
          this.parameterList.push(...datas)
        }
        // this.parameterList.push(...JSON.parse(JSON.stringify(this.datas[this.floderIndex].standList[inx].parameterList)))
        // this.parameterList = JSON.parse(JSON.stringify(this.datas[this.floderIndex].standList[inx].parameterList))
        // console.log(this.parameterList);
        // console.log(this.activeIndexTwo);
        console.log(this.parameterList)
        console.log(this.parameterInfoList)
        if (this.parameterInfoList.length) {
          for (let i = 0; i < this.parameterList.length; i++) {
            if (
              this.parameterList[i].id == this.parameterInfoList[0].parameterId
            ) {
              this.parameterInfoList = this.parameterList[i].parameterInfoList
              break
            }
          }
          // this.parameterList.forEach(item=>{
          // 	if(item.id==this.activeList.id){
          //            this.parameterInfoList=item.parameterInfoList
          // 	}
          // })
        }
        console.log(this.parameterInfoList)
        debugger
        for (let i = 0; i < this.unlimberTypeList.length; i++) {
          if (!this.unlimberTypeList[i].parameterInfoList.length) {
            this.unlimberTypeList.splice(i, 1)
            break
          }
        }

        // if (this.activeIndexTwo >= 0 && this.activeIndexTwo<datas.length) {
        // 	this.parameterInfoList = JSON.parse(JSON.stringify(this.datas[this.floderIndex].standList[inx].parameterList[this.activeIndexTwo].parameterInfoList))
        // }
        // 不采用过滤法，就直接添加
      }
      this.removeItem = {}
    },
    pitchUnlimberType (item, index) {
      let obj = {}
      this.unlimberParList = item.parameterInfoList.reduce((items, next) => {
        obj[next.id] ? '' : (obj[next.id] = true && items.push(next))
        return items
      }, [])
      this.unlimberTypeList.forEach(items => {
        if (items.id == item.id) {
          items.parameterInfoList = this.unlimberParList
        }
      })
      this.activeIndexFour = index
      this.removeItem = item
      this.activeIndexFive = -1
    },
    pitchParList (item, index) {
      this.activeIndexFive = index
      this.removeItem = item
    },
    // 查看全部
    lookAll () {
      this.activeIndexFour = -2
      let newArr = []
      for (let item of this.unlimberTypeList) {
        newArr.push(...item.parameterInfoList)
      }
      // 对最终结果去重
      var hash = {}
      // arr是要去重的对象数组
      newArr = newArr.reduce(function (item, next) {
        hash[next.name] ? '' : (hash[next.name] = true && item.push(next))
        return item
      }, [])
      this.unlimberParList = newArr
    },
    sure () {
      let newArr = []
      // console.log(this.unlimberTypeList)
      for (let item of this.unlimberTypeList) {
        newArr.push(...item.parameterInfoList)
      }
      // 对最终结果去重
      var hash = {}
      // arr是要去重的对象数组
      newArr = newArr.reduce(function (item, next) {
        hash[next.name] ? '' : (hash[next.name] = true && item.push(next))
        return item
      }, [])
      console.log('确认事件')
      this.$emit('templateSure', newArr)
    },
    typeMoveUp () {
      let index = this.activeIndexFour
      if (index <= 0) {
        return
      }
      let lastIndex = this.activeIndexFour - 1
      let temp = this.unlimberTypeList[index]
      this.unlimberTypeList[index] = this.unlimberTypeList[lastIndex]
      this.$set(this.unlimberTypeList, lastIndex, temp)
      this.activeIndexFour -= 1
    },
    typeMoveDown () {
      let index = this.activeIndexFour
      if (index >= this.unlimberTypeList.length - 1) {
        return
      }
      let lastIndex = this.activeIndexFour + 1
      let temp = this.unlimberTypeList[index]
      this.unlimberTypeList[index] = this.unlimberTypeList[lastIndex]
      this.$set(this.unlimberTypeList, lastIndex, temp)
      this.activeIndexFour += 1
    },
    parMoveUp () {
      let index = this.activeIndexFive
      if (index <= 0) {
        return
      }
      let lastIndex = this.activeIndexFive - 1
      let temp = this.unlimberParList[index]
      this.unlimberParList[index] = this.unlimberParList[lastIndex]
      this.$set(this.unlimberParList, lastIndex, temp)
      this.activeIndexFive -= 1
    },
    parMoveDown () {
      let index = this.activeIndexFive
      if (index >= this.unlimberParList.length - 1) {
        return
      }
      let lastIndex = this.activeIndexFour + 1
      let temp = this.unlimberParList[index]
      this.unlimberParList[index] = this.unlimberParList[lastIndex]
      this.$set(this.unlimberParList, lastIndex, temp)
      this.activeIndexFive += 1
    },
    // 添加分类
    addType () {
      this.activeIndexFour = -1
      this.activeIndexFive = -1
      this.isAddType = true
      setTimeout(() => {
        this.$refs.typeInput.focus()
      }, 1)
    },
    sureAddType () {
      if (this.addTypeName == '') {
        this.$message({
          message: '输入为空，已取消新增参数分类',
          type: 'warning'
        })
      } else {
        this.unlimberTypeList.push({
          id: this.chushiId,
          name: this.addTypeName,
          parameterInfoList: []
        })
        this.chushiId -= 1
        this.addTypeName = ''
      }
      this.isAddType = false
    },
    addInfo () {
      if (this.activeIndexFour < 0) {
        this.$message({
          message: '请先选择参数分类列表',
          type: 'warning'
        })
        return
      }
      this.activeIndexFive = -1
      this.isAddInfo = true
      setTimeout(() => {
        this.$refs.parInput.focus()
      }, 1)
    },
    sureAddInfo () {
      if (this.addInfoName == '') {
        this.$message({
          message: '输入为空，已取消新增参数',
          type: 'warning'
        })
        return
      } else {
        this.unlimberParList.push({
          id: this.chushiId,
          name: this.addInfoName
        })
        this.chushiId -= 1
        this.addInfoName = ''
      }
      this.isAddInfo = false
    },
    selectChange (val) {
      this.getStandardList(val)
      this.activeIndexOne = -1
      this.activeIndexTwo = -1
      this.activeIndexThree = -1
      this.activeIndexFour = -1
      this.activeIndexFive = -1
      this.activeItem = {}
      // this.fixCode={}
      this.activeNames = ['1']
      this.activeId = -1
      this.floderIndex = -1
      this.unlimberTypeList = []
      this.unlimberParList = []
      // this.parameterList = []
      this.parameterInfoList = []
      this.addPar = false
      // this.init()
      this.parameterList = [this.yuansiParType]
    },
    getStandardList (type) {
      this.$axios
        .get(`/api/standard/getstandardlist?type=${type}`)
        .then(res => {
          console.log(res)
          this.datas = res
          this.yuansiDatas = JSON.parse(JSON.stringify(res))
        })
    }
  }
}
</script>

<style scoped lang="less">
.tree-transfer-container {
  /deep/.el-input--mini .el-input__inner {
    padding-left: 10px;
  }

  // position: relative;
  display: flex;
  width: 100%;
  // height: 68px;
  // background-color: #f8f8f9;
  box-sizing: border-box;
  padding: 10px;

  .header-box {
    display: flex;
    justify-content: space-between;
    height: 36px;
    line-height: 36px;
    box-sizing: border-box;
    // padding-left: 15px;
    padding: 0 15px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    background-color: #f7f7f8;
    // font-size: 12px;
    text-align: left;
  }

  .header-box:nth-child(1) {
    // position: absolute;
    /deep/.el-input {
      // background-color: #000000;
      // height: 20px;
      margin-top: -5px;
    }
  }

  .main-box {
    height: calc(100% - 36px);
    background-color: #fff;
    overflow-y: auto;

    .title-scope {
      padding-left: 20px;
      // background-color: #000000;
      box-sizing: border-box;
      width: 210px;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      span:nth-child(1) {
        margin-right: 10px;
      }
    }

    ul {
      height: 100%;
      // max-height: 200px;
      // background-color: #000000;
      overflow: auto;
      .unlimberBox {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .addBox {
        display: flex;
        align-items: center;

        button {
          height: 24px;
          margin-left: 10px;
        }

        .el-button--primary {
          background-color: #475065;
          border-color: #475065;
        }
      }

      li {
        height: 40px;
        line-height: 40px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444;
        // color: #000000;
        text-align: left;
        padding: 0 10px 0 20px;
        box-sizing: border-box;
        border: 1px solid transparent;
        cursor: pointer;
      }

      .active {
        background-color: #f8f8f9;
        // border: 1px solid #b4b4b4;
      }
    }
  }

  .transfer-left {
    display: flex;
    justify-content: space-evenly;
    white-space: nowrap;
    text-overflow: ellipsis;
    // width: 50%;
    // box-shadow: 0 0 2px 2px #e5e5e5;

    .left-one,
    .left-two,
    .left-three {
      width: 237px;
      height: 653px;
      background: #ffffff;
      box-shadow: 0px 2px 15px 0px rgba(219, 219, 219, 0.5);
      border-top: 6px solid #475065;
    }

    // .left-three {
    // 	border: none;
    // }

    .left-one {
      .header-box {
        background-color: #475065;
        color: #fff;
      }
    }
  }

  .transfer-middle {
    display: flex;
    align-items: center;
    width: 91px;

    .btn-container {
      span {
        width: 59px;
        height: 35px;
        line-height: 35px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e3e3e3;
        font-weight: bold;
        cursor: pointer;
      }

      span:nth-child(2) {
        margin-top: 20px;
      }
    }
  }

  .transfer-right {
    display: flex;
    // width: 40%;
    // box-shadow: 0 0 2px 2px #e5e5e5;

    .right-one,
    .right-two {
      width: 237px;
      height: 654px;
      background: #ffffff;
      box-shadow: 0px 2px 15px 0px rgba(219, 219, 219, 0.5);
      border-top: 6px solid #475065;
    }

    .main-box {
      ul {
        height: calc(100% - 40px);
        overflow-y: auto;
      }

      div {
        display: flex;
        justify-content: left;
        align-items: center;

        span {
          width: 59px;
          height: 35px;
          background: #e9e9e9;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 35px;
          cursor: pointer;
          margin-left: 12px;
        }

        span:nth-child(1) {
          margin-left: 20px;
        }

        .sure {
          color: #fff;
          background: #475065;
        }
      }
    }
  }
}
</style>
