<template>
	<div class="change-floor">
		<el-dialog :visible.sync="changeFloorDia" :close-on-click-modal="false">
			<el-table
			      :data="tableData"
			      style="width: 100%">
			      <el-table-column
			        label="序号"
					align="center"
				  >
				  <template slot-scope="scope">
					  {{ scope.$index+1 }}
				  </template>
			      </el-table-column>
			      <el-table-column
			        prop="floorName"
			        label="楼层名称"
					align="center"
			      >
					<template slot-scope="scope">
						<span v-if="scope.$index != fixRow" @click="fixMth(scope)">{{ scope.row.floorName }}</span>
						<div style="width: 120px;margin-left: 100px;">
							<el-input ref="input" size="mini" v-if="scope.$index == fixRow" v-model="inputValue" @blur="sureMth"></el-input>
						</div>
					</template>
			      </el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
export default {
  name: 'ChangeFloor',
  props: ['tableData', 'mappingtable'],
  data () {
    return {
      changeFloorDia: false,
      tableDataList: [],
      inputValue: '',
      oldValue: '',
      changeItem: {},
      fixRow: -1
    }
  },
  created () {
  },
  mounted () {
    this.tableDataList = this.tableData
  },
  methods: {
    fixMth (scope) {
      console.log(scope)
      this.changeItem = scope.row
      this.inputValue = scope.row.floorName
      this.oldValue = scope.row.floorName
      this.fixRow = scope.$index
      setTimeout(() => {
        // console.log(that.$refs.inputRef)
        this.$refs.input.focus()
      }, 10)
    },
    sureMth () {
      const that = this
      this.fixRow = -1
      if (this.oldValue == this.inputValue) {
        this.$message.warning('未发生修改')
        return
      }
      var data = {
        floorId: this.changeItem.floorId,
        floorName: this.inputValue,
        mappingTable: this.$store.state.tableShow.showViewId
      }
      this.$axios.post('/api/formhandle/updatefloor', data).then(res => {
        that.$message.success('修改成功')
        that.$emit('changeFloorDataWeb', data)
      })
      this.inputValue = ''
      this.changeItem = {}
      this.oldValue = ''
    }
  }
}
</script>

<style>
</style>
