<template>
	<div v-if="showViewId==0">
		<div class="tablelist-box">
			<!-- 头部功能区 -->
			<div class="tableList-header-box">
				<div class="header-left">
					<!-- <span style="min-width: 100px;">表单列表</span> -->
					<!-- <p>{{projectname}}<span class="el-icon-arrow-right"></span></p> -->
				</div>

				<div class="header-right">
					<div class="search-container">
						<el-input
						  placeholder="请输入表名搜索"
						  v-model="searchValue"
						  @keyup.enter.native="search"
						  clearable>
						</el-input>
						<el-button class="search" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					</div>
					<div class="btn-container">
						<span :class="[isList?'actived':'noactived']" @click="changeShow">
							<i class="iconfont icon-a-02"></i>
						</span>
						<span :class="[!isList?'actived':'noactived']" @click="changeShow">
							<i class="iconfont icon-a-03"></i>
						</span>
					</div>
				</div>
			</div>
			<!-- 平铺展示区域 -->
			<div class="table-container" v-show="isList">
				<div v-for="item in standardList" class="item-box" @click="view(item)" :key="Math.random()">
				   <div class="item-right">
						<p style="text-align: left;"><img src="@/assets/default/form.png" width="25" height="25" class="formpng" />{{item.tablename}}</p>
						<!-- <p></p> -->
						<p><span>创建人：{{item.creatusernickname}}</span><span>创建时间：{{item.creattimestr}}</span></p>
						<p v-show="item.updateusernickname"><span>修改人：{{item.updateusernickname}}</span><span>修改时间：{{item.updatetimestr}}</span></p>
						<p style="text-align: center;" v-show="!item.updateusernickname">暂无修改记录</p>
						<div class="btn-box">
							<i class="el-icon-user" @click.stop="pullUserTable(item)" v-if="action.settablepermission"></i>
							<i class="el-icon-edit" @click.stop="updateShow(item)" v-if="action.updatetablename"></i>
							<i class="el-icon-delete" @click.stop="delet(item)" v-if="action.deletetable"></i>
						</div>
					</div>
				</div>
				<div v-if="standardList.length" class="item-box" v-show="isMore">
					<div class="overspread-box" @click="moreList">
						<p>点击加载更多...</p>
						<!-- <span class="el-icon-loading"></span> -->
					</div>
					<!-- <pagination :options="option" ref="pagination" @getPagingResult="getPagingResult" ></pagination> -->
				</div>
			</div>
			<div v-show="!isList" class="tableShow">
				  <el-table
				  ref="table"
				  @row-click="view"
				    :data="tableData"
				    stripe
					height="630px"

				    style="width: 100%">
				    <el-table-column
				      prop="tablename"
				      label="表名">
				    </el-table-column>
				    <el-table-column
				      prop="creatusernickname"
				      label="创建人">
				    </el-table-column>
				    <el-table-column
				      prop="creattimestr"
				      label="创建时间">
				    </el-table-column>
					<el-table-column
					  prop="updateusernickname"
					  label="修改人">
					</el-table-column>
					<el-table-column
					  prop="updatetimestr"
					  label="修改时间">
					</el-table-column>
					<el-table-column
					      label="操作">
					      <template slot-scope="scope">
					      <div class="btn-box">
							  <i class="el-icon-user" @click.stop="pullUserTable(scope.row)"></i>
							  <i class="el-icon-edit" @click.stop="updateShow(scope.row)" v-if="action.updatetablename"></i>
							  <i class="el-icon-delete" @click.stop="delet(scope.row)" v-if="action.deletetable"></i>
						  </div>
					      </template>
					    </el-table-column>
				  </el-table>
				  <pagination :pagesize=20 :options="option" ref="paginationTable" @getPagingResult="getPagingResultTable" ></pagination>
			</div>
		</div>

		<!-- 弹出框区域 -->
		<el-dialog title="修改表单" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
			<el-form :model="formObj" ref="tableupdateform" label-width="100px" class="demo-ruleForm">
				<el-form-item label=" 表名" prop="tablename" :rules="[
		        { required: true, message: '表名不能为空'}
		      ]">
					<el-input v-model="formObj.tablename"></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align: right;">
				<el-button type="danger" @click="dialogVisible=false">取消</el-button>
				<el-button type="primary" @click="tableupdate">确认</el-button>
			</div>
		</el-dialog>
		<!-- <el-dialog title="" :visible.sync="dialogUserTable" width="30%" :close-on-click-modal="false">
			<el-transfer height="500px" filterable :filter-method="filterMethod" filter-placeholder="请输入用户"  :titles="['项目成员', '表单成员']" v-model="userValue" :data="userTableList" style="text-align:left"></el-transfer>
			<p class="bottomBtn">
				<el-button type="danger" @click="dialogUserTable=false">取消</el-button>
				<el-button type="primary" class="search" @click="tableUserupdate">确认</el-button>
			</p>
		</el-dialog> -->
		<div v-if="addUserFlag">
			<add-user ref="addUser" :userTableList="userTableList" :mappingtable="mappingtable" :userSource = "userSource" :userValueC="userValue"></add-user>
		</div>
	</div>
	<div v-else>
		<table-item ref="tableTemplate" :tables="tableData" :highElementIds="highElementIds" :tableGuidC="showViewId" :tablenameC="tablename" :codes="fixCode"></table-item>
	</div>
</template>
<script>
import {
  mapState, mapMutations
} from 'vuex'
import highlightMeth from '../../utils/highlightmth.js'
import TableItem from './Table'
import Pagination from '../../components/Pagination.vue'
import AddUser from '../../components/addUser.vue'
export default {

  data () {
    return {
      // showViewId: 0,
      standardList: [],
      highElementIds: [],
      userId: localStorage.getItem('USER_ID'),
      tablename: '',
      labelTable: '',
      isUpdateID: 0,
      focusState: false,
      dialogVisible: false,
      formObj: {},
      oldName: '',
      // 穿梭框
      dialogUserTable: false,
      userList: [], // 项目下所有用户
      userSource: [], // 原始数据源，用于提交时判断那些是删除的，哪些是添加的
      filterMethod (query, item) {
        return item.label.indexOf(query) > -1
      },
      mappingtable: '',
      userValue: [],
      userTableList: [],
      userTableIds: [],
      isList: true, // 是不是平铺的展现形式
      option: {
        param: {
          serachText: null
        },
        servicePage: false,
        serachField: ['tablename'], // 需要搜索的字段数组
        url: '/api/formhandle/getalltable'
      },
      tableData: [],
      searchValue: '',
      projectname: '',
      isMore: true,
      fixCode: {
        spaceCode: '',
        projectCode: '',
        monomer: '',
        floor: '',
        componentCode: '',
        serialNumber: ''
      },
      addUserFlag: false
    }
  },
  components: {
    TableItem, Pagination, AddUser
  },
  directives: {
    focus: {
      update: function (el, {
        value
      }) {
        if (value) {
          el.children[0].focus()
        }
      }
    }
  },
  mounted () {
    this.init()
    // this.automatically()
    this.getSpecialCode()
    // highlightMeth.$on('high',(msg)=>{
    // 	this.view(msg)
    // 	this.highElementIds = msg.highElementIds
    // 	this.$refs.tableTemplate.getData()
    // })
    this.projectname = localStorage.getItem('projectName')
    // 加载上一次为保存的修改记录并保存
    // console.log(this.tableShow)
  },
  computed: {
    ...mapState('action', {
      action: 'action'
    }),
    ...mapState('tableShow', {
      showViewId: 'showViewId'
    })
  },
  destroyed () {
    this.initShowViewId(0)
  },
  methods: {
    ...mapMutations('tableShow', {
      initShowViewId: 'initShowViewId'
    }),
    ...mapMutations('tableShow', {
      initCreateUserId: 'initCreateUserId'
    }),

    // 在这里获取固定的编码不在table中获取，可以减少请求次数
    getSpecialCode () {
      this.$axios.get('/api/formhandle/getspecialcode').then(res => {
        let codes = res.specialCode_tableHeaderName
        this.fixCode.spaceCode = codes[0]
        this.fixCode.projectCode = codes[1]
        this.fixCode.monomer = codes[2]
        this.fixCode.floor = codes[3]
        this.fixCode.componentCode = codes[4]
        this.fixCode.serialNumber = codes[5]
      })
    },
    // 判断是否为接收到消息推送之后的自动跳转
    automatically () {
      if (!this.$route.params.mappingtable) {
        return
      }
      const par = this.$route.params
      this.view(par)
      this.highElementIds = par.highElementIds
    },
    init () {
      this.$axios.get('/api/User/GetUsers').then(res => {
        this.userList = res
        var _this = this
        _this.userTableList = []
        res.forEach(element => {
          _this.userTableList.push({
            key: element.userId,
            // label: element.userName+'('+element.nickName+')',
            label: element.nickName,
            userName: element.userName,
            disabled: false
          })
        })
        localStorage.setItem('userTableList', JSON.stringify(this.userTableList))
      })
    },
    // 修改表名
    updateShow (item) {
      var _this = this
      this.formObj = JSON.parse(JSON.stringify(item))
      this.oldName = item.tablename
      this.dialogVisible = true
      this.labelTable = item.tablename
      this.isUpdateID = item.mappingtable
      setTimeout(() => {
        _this.focusState = true
      }, 1)
    },
    tableUserupdate () {
      var _this = this
      var addDto = {
        MappingTable: '',
        DeleteUserIds: [],
        AddUserIds: []
      }
      addDto.MappingTable = this.mappingtable
      this.userSource.forEach(element => {
        if (_this.userValue.findIndex(n => n == element) == -1) {
          addDto.DeleteUserIds.push(element)
        }
      })
      this.userValue.forEach(element => {
        if (_this.userSource.findIndex(n => n == element) == -1) {
          addDto.AddUserIds.push(element)
        }
      })
      this.$axios.post('/api/UserTable/AddUserTable', addDto)
        .then(res => {
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this.dialogUserTable = false
        })
    },
    tableupdate () {
      this.$refs.tableupdateform.validate(valid => {
        if (valid) {
          if (this.oldName == this.formObj.tablename) {
            this.$message({
              showClose: true,
              message: '表名未修改',
              type: 'warning'
            })
          } else {
            this.$axios.get(
              `/api/formhandle/updatetable?mappingTable=${this.formObj.mappingtable}&newTableName=${this.formObj.tablename}`
            ).then(res => {
              console.log(res)
              if (res == '修改成功') {
                this.$message({
                  showClose: true,
                  message: '修改成功',
                  type: 'success'
                })
              }
              // 前端更新数据
              let index = this.standardList.findIndex(item => {
                return item.mappingtable == this.formObj.mappingtable
              })
              this.$set(this.standardList, index, this.formObj)
              this.dialogVisible = false
            })
          }
        } else {
          return false
        }
      })
    },
    update (item) {
      this.$axios.get('/api/formhandle/updatetable?mappingTable=' + item.mappingtable + '&newTableName=' + this.labelTable)
        .then(res => {
          item.tablename = this.labelTable
          this.labelTable = ''
          this.isUpdateID = 0
          this.focusState = false
        })
    },
    onInputBlur () {
      this.labelTable = ''
      this.isUpdateID = 0
      this.focusState = false
    },
    pullUserTable (item) {
      console.log(item)
      this.addUserFlag = false
      var _this = this
      this.mappingtable = item.mappingtable
      this.userValue = []
      // this.userSource = []
      // this.mappingtable = item.mappingtable
      // // 添加创始人
      this.$axios.get('/api/UserTable/GetUserList?MappingTable=' + item.mappingtable)
        .then(res => {
          console.log(res)
          res.forEach(element => {
            _this.userSource.push(element.userId)
            if (_this.userTableList.findIndex(n => n.key == element.userId) > -1) {
              _this.userValue.push(element.userId)
            }
          })
          // debugger
          const createrId = item.creatuserid
          let ind = this.userTableList.findIndex(i => {
            return i.key == createrId
          })
          this.userTableList.forEach(item => {
            item.disabled = false
          })
          this.userTableList[ind].disabled = true
          this.userValue.push(item.creatuserid)
          this.userValue = [...new Set(this.userValue)]
          this.addUserFlag = true
          this.$nextTick(() => {
            this.$refs.addUser.dialogUserTable = true
          })
        })
    },
    delet (item) {
      this.$confirm('此操作将删除该表格, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var arr = []
        arr.push(item.mappingtable)
        this.$axios.post('/api/formhandle/deletetable', arr).then(res => {
          if (res == '删除成功') {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            })
            this.standardList = this.standardList.filter(item => {
              return item.mappingtable != arr[0]
            })
            this.tableData = this.standardList.filter(item => {
              return item.mappingtable != arr[0]
            })
          }
        })
      })
    },
    view (item) {
      console.log(item)
      if (!this.action.viewtable) {
        this.$message({
          showClose: true,
          message: '您没有此表格的查看权限',
          type: 'warning'
        })
        return
      }
      // localStorage.setItem("tableNameC",this.tablename)

      // this.showViewId = item.mappingtable
      this.initShowViewId(item.mappingtable)
      this.initCreateUserId(item.creatuserid)
      this.tablename = item.tablename
      localStorage.setItem('tablename', item.tablename)
      localStorage.setItem('tableGuidC', this.showViewId)
      localStorage.setItem('fixCode', JSON.stringify(this.fixCode))
    },
    // 还原，子界面返回此界面
    reback () {
      // this.showViewId = 0;
      this.initShowViewId(0)
      this.init()
    },
    // 切换展现方式
    changeShow () {
      this.isList = !this.isList
    },
    // 平铺展现时获取数据
    getPagingResult (result) {
      console.log(121)
      var total = this.$refs.pagination.currtotal
      if (this.standardList.length < total) {
        this.standardList.push(...result)
      }
    },
    // 点击加载更多
    moreList () {
      var total = this.$refs.paginationTable.currtotal
      var current = this.$refs.paginationTable.currpageIndex
      if (this.standardList.length >= total) {
        this.$message({
          showClose: true,
          message: '没有更多',
          type: 'warning'
        })
      } else {
        current++
        this.$refs.paginationTable.pageChange(current)
      }
    },
    // table中展现的数据
    getPagingResultTable (result) {
      var total = this.$refs.paginationTable.currtotal
      console.log(this.$refs.paginationTable.currpageIndex)
      if (this.$refs.paginationTable.currpageIndex == 1) {
        this.standardList = []
      }
      if (this.standardList.length < total) {
        this.standardList.push(...result)
      }
      // 数据更新上去之后再判断是不是加载完了，如果是就不能更多
      if (this.standardList.length >= total) {
        this.isMore = false
      } else {
        this.isMore = true
      }
      this.tableData = result
    },
    // 搜索
    search () {
      if (this.isList) {
        this.standardList = []
        this.option.param.serachText = this.searchValue
        this.$refs.paginationTable.serach(1)
      } else {
        this.option.param.serachText = this.searchValue
        this.$refs.paginationTable.serach(1)
      }
    }
  }
}
</script>
<style scoped lang="less">

	.formpng {
		float: left;
		margin-right: 10px;
		margin-top: -3px;
	}
	/deep/.el-transfer-panel__body{
		height: 400px;
	}
	.tablelist-box {
		padding: 15px 25px;
		// height: calc(100vh - 100px);
		height: 877px;
		box-sizing: border-box;

		.tableList-header-box{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 69px;
				border-radius: 8px;
				margin-bottom: 10px;
				background-color: transparent;

			.header-left {
				margin-left: 0;
				p{
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 266px;
					height: 48px;
					background: #FFFFFF;
					box-shadow: 0px 2px 4px 0px rgba(170, 170, 170, 0.5), 0px 2px 15px 0px rgba(241, 241, 241, 0.5);
					border-radius: 8px;
					box-sizing: border-box;
					padding: 0 30px;
					cursor: pointer;
					span{
						font-weight: bold;
					}
				}
			}
			.header-right {
				display: flex;
				height: 40px;
				margin-right: 30px;
				align-items: center;
				.search-container{
					display: flex;
					/deep/.search {
						background-color: #475065;
						border: none;
						margin-left: 5px;
					}
				}
				.btn-container{
					display: flex;
					margin-left: 10px;
					border: 1px solid #475065;
					border-radius: 10px;
					overflow: hidden;
					span{
						display: inline-block;
						width: 40px;
						height: 40px;
						line-height: 40px;
					}
					.actived{
						color: #FFF;
						background-color: #475065;
					}
					.noactived{
						color: #475065;
						background-color: #FFF;
					}
				}
			}
		}
		.table-container {
			display: flex;
			// height: calc(100% - 40px);
			height: 767px;
			// background-color: #000000;
			flex-wrap: wrap;
			overflow-y: auto;
			align-content: flex-start;
			.item-box:hover .btn-box {
				display: block !important;
			}
			.item-box {
				position: relative;
				overflow: hidden;
				cursor: pointer;
				width: 23.5%;
				min-width: 380px;
				height: 139px;
				box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
				border-radius: 8px;
				// margin: 10px;
				margin: 0 20px 20px 0;
				box-sizing: border-box;
				padding: 20px 30px;
				background: #fff;
				.overspread-box{
					width: 100%;
					height: 100%;
					font-size: 25px;
					box-sizing: border-box;
					padding: 32px;
				}
				.item-right {
					height: 100px;
					.btn-box {
						display: none;
						position: absolute;
						top: 20px;
						right: 0px;
						color: #409eff;
					}

					.btn-box i:nth-child(1) {
						margin-right: 20px;
					}
					.btn-box i:nth-child(2) {
						margin-right: 20px;
					}
					.btn-box i:nth-child(3) {
						margin-right: 10px;
					}
				}

				.item-right p:nth-child(1) {
					font-size: 14px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #5F5F5F;
					margin-bottom: 26px;
				}

				.item-right p span:nth-child(1) {
					margin-right: 28px;
				}

				.item-right p:nth-child(2) {
					margin-bottom: 10px;
				}

				.item-right p:nth-child(1) span:nth-child(1) {
					margin-right: 15px;
				}

				.item-right p {
					text-align: left;
					font-size: 16px;
					line-height: 20px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #5F5F5F;
				}

			}
		}
		.tableShow{
			.btn-box{
				color: #409eff;
				i{
					margin-left: 8px;
				}
			}
			/deep/tbody{
				tr{
					cursor: pointer;
				}
			}
		}

	}
	// /deep/.el-transfer-panel{
	// width: 40%;
	// height: 500px;
	// }
	// /deep/.el-transfer-panel__list.is-filterable{
	// height: 400px;
	// }
	// /deep/.bottomBtn {
	// 	// width: 75%;
	// 	text-align: right;
	// 	margin-top: 15px;
	// 	.search {
	// 		background-color: #475065;
	// 		border: none;
	// 		margin-left: 5px;
	// 	}
	// }
	// /deep/.el-transfer__button {
	// 	background-color: #475065;
	// 	border: 1px solid #475065;
	// }

</style>
